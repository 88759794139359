import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { role } from '../../config/constants/roles';
import { SubMenuItemType } from '../../config/constants/menu.types';

export const getTransactionPayinMenu = (userScopes: string[]) => {
  const TransactionPayinsMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.requestPayments,
      icon: 'arrow-right',
      title: 'transactions',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
      id: 'mp-payins-transactions-action',
    },
  ];

  return TransactionPayinsMenu;
};

const getTransactionPayinRoutes = (userScopes: string[]) => {
  const TransactionPayinRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.requestPayments,
      component: React.lazy(() => import('../../pages/transactions/transactions')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
    {
      path: RoutePaths.requestPaymentDetail,
      component: React.lazy(
        () => import('../../pages/transactions/transaction-detail/transaction-detail'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
  ];

  return TransactionPayinRoutes;
};

export default getTransactionPayinRoutes;
