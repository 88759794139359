import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { role } from '../../config/constants/roles';
import { SubMenuItemType } from '../../config/constants/menu.types';

export const getTransactionPayoutMenu = (userScopes: string[]) => {
  const TransactionPayoutMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.payroll,
      icon: 'dollar-sign',
      title: 'transactions',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
      id: 'mp-payouts-transaction-action',
    },
  ];

  return TransactionPayoutMenu;
};

const getTransactionPayoutRoutes = (userScopes: string[]) => {
  const TransactionPayoutRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.payroll,
      component: React.lazy(() => import('../../pages/payroll/payroll-page')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
    },
    {
      path: RoutePaths.payrollDetail,
      component: React.lazy(() => import('../../pages/payroll/payroll-detail/payroll-detail')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
    },
  ];

  return TransactionPayoutRoutes;
};

export default getTransactionPayoutRoutes;
