import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Total } from '../../types/services';
import { createSelector } from 'reselect';
import { RootState } from '../store';
import { TotalState } from './types';

export const totalEmpty: Total = {
  total: -1,
  amount: -1,
  currency: '',
};

const initialState: TotalState = {
  totals: totalEmpty,
};

export const totalSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    totals: (state, action: PayloadAction<Total>) => {
      state.totals = action.payload;
    },
  },
});

// Selectors
const getTotal = (state: RootState): TotalState => state.total;
export const getTotals = createSelector(getTotal, (filter) => filter.totals);

const { actions, reducer } = totalSlice;

export const { totals } = actions;

export default reducer;
