/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import IntlPolyfill, { NumberFormatOptions } from 'intl';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

import Languages from './constants/languages';

import 'intl/locale-data/jsonp/en';
// import 'moment/min/locales';
import 'moment/locale/es';
import 'moment/locale/id';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pt';
import 'moment/locale/th';
import 'moment/locale/tr';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/ru';
import { pascalCase, removePrefix } from '../helpers/string';
import { getQueryParam } from '../helpers/url';

const awsLocalizationBucketUrl = process.env.REACT_APP_AWS_TRANSLATE;

const detection = {
  lookupLocalStorage: 'language',
};
const fallbackLng = 'en';
const lng = getQueryParam('language') || undefined;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng,
    load: 'languageOnly',
    fallbackLng,
    debug: false,
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    detection,
    react: {
      wait: true,
      useSuspense: false,
      bindI18n: 'languageChanged',
    },
    backend: {
      loadPath: function (lng: string) {
        const fileName = `${lng.length > 0 ? lng[0] : fallbackLng}.json`;
        return `${awsLocalizationBucketUrl}${fileName}?t=${new Date().getTime()}`;
      },
      crossDomain: true,
    },
  });

const intlInstanceNumberFormat = (
  lng: string,
  number: number,
  options: IntlPolyfill.NumberFormatOptions,
) =>
  new IntlPolyfill.NumberFormat(lng, options)
    .formatToParts(number)
    .map(({ type, value }) => {
      if (type === 'currency') {
        return `<span className="currency">${value}</span>`;
      } else {
        return value;
      }
    })
    .reduce((string, part) => string + part);

export let formatterNumber = (number: number, options: IntlPolyfill.NumberFormatOptions) =>
  intlInstanceNumberFormat('en', number, options);

i18n.on('languageChanged', function (lng) {
  // const realLng = lng === 'zht' ? 'zh-TW' : lng;
  let realLng = lng;
  const intlLng = lng.replace('_', '-');
  switch (lng) {
    case 'zh':
      realLng = 'zh-CN';
      break;
    case 'zht':
      realLng = 'zh-TW';
      break;
  }
  formatterNumber = (number: number, options: NumberFormatOptions = {}) =>
    intlInstanceNumberFormat(intlLng, number, options);
  moment.locale(realLng);
});

export const getLanguage = () => {
  const lng = i18n.language;
  if (!lng) return lng;
  if (lng.split('-').length === 1) return lng;
  return Languages.reduce((real, l) => {
    const ln1 = l.code.split('-');
    const ln2 = lng.split('-');
    if (ln1[0] === ln2[0]) {
      if (ln2[0] === 'zh' && ln2[1] !== undefined && ln2[1] === 'TW') {
        return `${ln2[0]}_${ln2[1]}`;
      }
      return ln1[0];
    }
    return real;
  }, fallbackLng);
};

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang).then(() => {
    window.location.reload();
  });
};

export const translateWithPrefix = (term: string, prefix = 'label', params?: TOptions | string) => {
  const removePossiblyRedundantPrefix = removePrefix(term, prefix);
  const key = `${prefix}${pascalCase(removePossiblyRedundantPrefix)}`;
  const translatedKey = translate(key, params);
  return translatedKey === key ? term : translatedKey;
};

export const translate = (key: string, params?: TOptions | string) =>
  ourEscapeMethod(i18n.t(key, params));

const ourEscapeMethod = (s: string) => {
  //TODO: This is a small workaround for the current situation
  // We need to take a better look on it later
  if (!s) {
    return '';
  }
  return s.replace('\\', '');
};

export default i18n;
