import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { role } from '../../config/constants/roles';
import { SubMenuItemType } from '../../config/constants/menu.types';

export const getCustomerMenu = (userScopes: string[]) => {
  const customersMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.customers,
      icon: 'user',
      title: 'customers',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
      id: 'mp-payins-customer-action',
    },
  ];

  return customersMenu;
};

const getCustomerRoutes = (userScopes: string[]) => {
  const CustomerRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.customers,
      component: React.lazy(() => import('../../pages/customers/customers')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
    {
      path: RoutePaths.customerDetail,
      component: React.lazy(() => import('../../pages/customers/customer-detail/customer-detail')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
  ];

  return CustomerRoutes;
};

export default getCustomerRoutes;
