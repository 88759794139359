import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getDepositMenu = (userScopes: string[]) => {
  const DepositMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.depositList,
      icon: 'arrow-left',
      title: 'nav_deposit',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.transactionsReports),
    },
  ];
  return DepositMenu;
};

const getDepositRoutes = (userScopes: string[]) => {
  const DepositRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.depositList,
      component: React.lazy(() => import('../../pages/deposit/deposit-page')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.transactionsReports),
    },
    {
      path: RoutePaths.depositDetail,
      component: React.lazy(() => import('../../pages/deposit/detail/deposit-details')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.transactionsDetails),
    },
  ];
  return DepositRoutes;
};

export default getDepositRoutes;
