import moment from 'moment';

/* eslint-disable @typescript-eslint/naming-convention */
enum DateFormat {
  RegularDate = 'L',
  DisplayBirthDate = 'DD/MM/YYYY',
  BirthDate = 'YYYY-MM-DD[T00:00:00Z]',
  RegularDateTime = 'L H:mm:ss',
  TextDateTime = 'll LTS',
  TextDate = 'll',
  MonthAndYear = 'MM/YYYY',
}

const createdFrom = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
const createdTo = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
export const dateByDefault = `?&createdFrom=${createdFrom}&createdTo=${createdTo}`;

const yearFrom = moment().subtract(12, 'month').format('YYYY-MM-DDTHH:mm:ss');
const yearTo = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
export const dateYearByDefault = `?&createdFrom=${yearFrom}&createdTo=${yearTo}`;

const weekFrom = moment().subtract(1, 'week').format('YYYY-MM-DDTHH:mm:ss');
const weekTo = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
export const dateWeekByDefault = `?&createdFrom=${weekFrom}&createdTo=${weekTo}`;

const dateFrom = moment().startOf('month').subtract(1, 'day').format('YYYY-MM-DD');
const dateTo = moment().format('YYYY-MM-DD');
export const balanceReportDateByDefault = `?&dateFrom=${dateFrom}&dateTo=${dateTo}`;

const payrollDateFrom = moment().subtract(3, 'month').format('YYYY-MM-DD');
const payrollDateTo = moment().endOf('day').format('YYYY-MM-DD');
export const payrollDateByDefault = `?&dateFrom=${payrollDateFrom}&dateTo=${payrollDateTo}`;

export default DateFormat;
