import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { PayrollFilterState } from './types';
import { payrollDateByDefault } from '../../../config/constants/date-formats';
import { createSelector } from 'reselect';

const initialState: PayrollFilterState = {
  payrollFilters: payrollDateByDefault,
  payrollDates: payrollDateByDefault,
  payrollExternalId: '',
};

export const payrollFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    payrollFilters: (state, action: PayloadAction<string>) => {
      state.payrollFilters = action.payload;
    },
    payrollDates: (state, action: PayloadAction<string>) => {
      state.payrollDates = action.payload;
    },
    payrollExternalId: (state, action: PayloadAction<string>) => {
      state.payrollExternalId = action.payload;
    },
    resetPayrollFilters: (state) => {
      state.payrollFilters = initialState.payrollFilters;
      state.payrollDates = initialState.payrollDates;
      state.payrollExternalId = initialState.payrollExternalId;
    },
  },
});

// Payroll Selectors
const getPayrollFilters = (state: RootState): PayrollFilterState => state.payrollFilters;

export const getPayrollsFilters = createSelector(
  getPayrollFilters,
  (filter) => filter.payrollFilters,
);
export const getPayrollDates = createSelector(getPayrollFilters, (filter) => filter.payrollDates);
export const getPayrollExternalId = createSelector(
  getPayrollFilters,
  (filter) => filter.payrollExternalId,
);
const { actions, reducer } = payrollFilterSlice;

export const { payrollFilters, payrollDates, payrollExternalId, resetPayrollFilters } = actions;

export default reducer;
