import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { BalanceFilterState } from './types';
import { balanceReportDateByDefault } from '../../../config/constants/date-formats';

const initialState: BalanceFilterState = {
  balanceFilters: balanceReportDateByDefault,
  balanceDate: balanceReportDateByDefault,
};

export const balanceFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    balanceFilters: (state, action: PayloadAction<string>) => {
      state.balanceFilters = action.payload;
    },
    balanceDate: (state, action: PayloadAction<string>) => {
      state.balanceDate = action.payload;
    },
    resetBalanceFilters: (state) => {
      state.balanceFilters = initialState.balanceFilters;
      state.balanceDate = initialState.balanceDate;
    },
  },
});

// Selectors
const getBalanceFilters = (state: RootState): BalanceFilterState => state.balanceFilters;
export const getBalancesFilters = createSelector(
  getBalanceFilters,
  (filter) => filter.balanceFilters,
);
export const getBalanceDate = createSelector(getBalanceFilters, (filter) => filter.balanceDate);

const { actions, reducer } = balanceFilterSlice;

export const { balanceFilters, balanceDate, resetBalanceFilters } = actions;

export default reducer;
