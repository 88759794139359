import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  Theme as AugmentedTheme,
  makeStyles,
  createStyles,
  darken,
} from '@material-ui/core/styles';

// New Palette
const primary = '#20D5B3';
//Colors
const blue = '#2799f0';
const lightBlue = '#D1E9FF';
const yellow = '#ffc95c';
const black = '#14211F';
const gray = '#4a4a48';
const green = '#3bd5a9';
const purple = '#9e55b8';
const cyan = '#3bcfd5';
const bgDefault = '#fff';

//Grays
const gray100 = '#f7f7f7';
const gray200 = '#f3f3f3';
const gray300 = '#e8e8e8';
const gray400 = '#d1d1d1';
const gray500 = '#a4a4a3';
const gray600 = '#777776';
const gray800 = '#323231';

//Red
const redb600 = '#660000';
const redb400 = '#990000';
const redb200 = '#cc0000';
const red = '#E60022';
const redw400 = '#ff4b54';
const redw600 = '#ff9999';
const redw700 = '#ffb8b8';
const redw850 = '#ffdbdb';
const redw900 = '#ffe6e6';
const redw950 = '#fff2f2';

//Green
const greenb840 = '#237f65';
const greenb820 = '#2faa87';
const greenw700 = '#c4f2e5';
const greenw950 = '#f5fcfa';

const customTheme = {
  darkMode: false,
  customColors: {
    blue,
    yellow,
    black,
    gray,
    green,
    purple,
    cyan,
    red,
    reds: {
      redb600,
      redb400,
      redb200,
      redw400,
      redw600,
      redw700,
      redw850,
      redw900,
      redw950,
    },
    greens: {
      greenb840,
      greenb820,
      greenw700,
      greenw950,
    },
  },
};

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    darkMode: boolean;
    customColors: {
      blue: string;
      yellow: string;
      black: string;
      gray: string;
      green: string;
      purple: string;
      cyan: string;
      red: string;
      reds: {
        redb600: string;
        redb400: string;
        redb200: string;
        redw400: string;
        redw600: string;
        redw700: string;
        redw850: string;
        redw900: string;
        redw950: string;
      };
      greens: {
        greenb840: string;
        greenb820: string;
        greenw700: string;
        greenw950: string;
      };
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    darkMode: boolean;
    customColors: {
      blue: string;
      yellow: string;
      black: string;
      gray: string;
      green: string;
      purple: string;
      cyan: string;
      red: string;
      reds: {
        redb600: string;
        redb400: string;
        redb200: string;
        redw400: string;
        redw600: string;
        redw700: string;
        redw850: string;
        redw900: string;
        redw950: string;
      };
      greens: {
        greenb840: string;
        greenb820: string;
        greenw700: string;
        greenw950: string;
      };
    };
  }
}
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: { backgroundColor: '#FFF', color: '#2D2D2D' },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: darken(primary, 0.15),
          boxShadow: 'none',
        },
      },
      textPrimary: {
        color: blue,
      },
    },
    MuiLink: {
      root: {
        color: 'black',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
      elevation5: {
        boxShadow:
          '0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 5px 8px 0px rgb(0 0 0 / 8%), 0px 1px 14px 0px rgb(0 0 0 / 6%)',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'transparent !important',
        '&$disabled': {
          background: gray300,
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: gray400,
          },
        },
      },
    },
    MuiListItemText: {
      root: {
        '& .MuiListItemText-secondary': {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 42,
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: primary,
    },
    secondary: {
      main: blue,
      light: lightBlue,
    },
    warning: {
      main: yellow,
    },
    background: {
      default: bgDefault,
    },
    common: {
      black,
    },
    divider: gray300,
    grey: {
      '100': gray100,
      '200': gray200,
      '300': gray300,
      '400': gray400,
      '500': gray500,
      '600': gray600,
      '800': gray800,
    },
    error: {
      main: redw400,
    },
    success: {
      main: green,
    },
    tonalOffset: 0.2,
  },
  ...customTheme,
});

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    primary: {
      color: theme.palette.common.white,
      backgroundColor: `${primary} !important`,
    },
    success: {
      color: theme.palette.common.white,
      backgroundColor: `${theme.customColors.green} !important`,
    },
    danger: {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.error.main} !important`,
    },
    pending: {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.warning.main} !important`,
    },
    default: {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.grey[400]} !important`,
    },
  }),
);

const textStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    primary: {
      color: theme.customColors.blue,
    },
    success: {
      color: theme.customColors.green,
    },
    danger: {
      color: theme.palette.error.main,
    },
    pending: {
      color: theme.palette.warning.main,
    },
    default: {
      color: theme.palette.grey[400],
    },
  }),
);
/**
 * Returns the classes of `status`
 *
 *
 * @param {string} originalStatus Status string
 * @returns {string} Returns the classes of the status
 */
export const getStatusColor = (status: string | boolean) => {
  const classes = useStyles();
  switch (status) {
    case 'NO':
    case 'ENTRY':
      return classes.primary;
    case 'PENDING':
    case 'VALIDATION PENDING':
    case 'PENDING_ACTIVATION':
    case 'PROCESSING':
      return classes.pending;
    case 'COMPLETED':
    case 'CONFIRMED':
    case 'APPROVED':
    case 'ACTIVE':
    case 'OK':
    case 'CREDIT':
    case true:
      return classes.success;
    case 'CANCELED':
    case 'CANCELLED':
    case 'BLOCKED':
    case 'ERROR':
    case 'RESTRICTED':
    case 'DEBIT':
    case 'INACTIVE':
    case 'REJECTED':
    case false:
      return classes.danger;
    default:
      return classes.default;
  }
};

export const getTextColor = (status: string | number) => {
  const classes = textStyles();
  switch (status) {
    case 'PENDING':
      return classes.pending;
    case 'COMPLETED':
    case 'CONFIRMED':
    case 'APPROVED':
    case 'ACTIVE':
    case 'OK':
    case 200:
      return classes.success;
    case 'CANCELED':
    case 'CANCELLED':
    case 'BLOCKED':
    case 'ERROR':
    case 404:
    case 412:
    case 500:
      return classes.danger;
    default:
      return classes.default;
  }
};

export default theme;
