import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getUploadCashoutMenu = (userScopes: string[]) => {
  const UploadCashoutMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.uploadCashoutFile,
      icon: 'file-csv',
      title: 'upload_files',
      roles: [role.merchant, role.internal],
      hasPermission: hasPermission(userScopes, scopes.uploadCashout),
      id: 'mp-main-upload-cashout-action',
    },
  ];
  return UploadCashoutMenu;
};

const getUploadCashoutRoutes = (userScopes: string[]) => {
  const CashoutRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.uploadCashoutFile,
      component: React.lazy(() => import('../../pages/cashout/upload-file')),
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.uploadCashout),
    },
  ];

  return CashoutRoutes;
};

export default getUploadCashoutRoutes;
