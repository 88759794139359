import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { CustomerFilterState } from './types';
import { createSelector } from 'reselect';

const initialState: CustomerFilterState = {
  customerFilters: '',
  customerFirstName: '',
  customerLastName: '',
  customerPhone: '',
  customerStatus: '',
  customerCountry: '',
};

export const customerFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    customerFilters: (state, action: PayloadAction<string>) => {
      state.customerFilters = action.payload;
    },
    customerFirstName: (state, action: PayloadAction<string>) => {
      state.customerFirstName = action.payload;
    },
    customerLastName: (state, action: PayloadAction<string>) => {
      state.customerLastName = action.payload;
    },
    customerPhone: (state, action: PayloadAction<string>) => {
      state.customerPhone = action.payload;
    },
    customerCountry: (state, action: PayloadAction<string>) => {
      state.customerCountry = action.payload;
    },
    customerStatus: (state, action: PayloadAction<string>) => {
      state.customerStatus = action.payload;
    },
    resetCustomerFilters: (state) => {
      state.customerFilters = initialState.customerFilters;
      state.customerFirstName = initialState.customerFirstName;
      state.customerLastName = initialState.customerLastName;
      state.customerPhone = initialState.customerPhone;
      state.customerStatus = initialState.customerStatus;
      state.customerCountry = initialState.customerCountry;
    },
  },
});

// Customer Module Selectors
const getCustomerFilters = (state: RootState): CustomerFilterState => state.customerFilters;

export const getCustomersFilter = createSelector(
  getCustomerFilters,
  (filter) => filter.customerFilters,
);

export const getCustomerFirstName = createSelector(
  getCustomerFilters,
  (filter) => filter.customerFirstName,
);

export const getCustomerLastName = createSelector(
  getCustomerFilters,
  (filter) => filter.customerLastName,
);

export const getCustomerPhone = createSelector(
  getCustomerFilters,
  (filter) => filter.customerPhone,
);

export const getCustomerCountry = createSelector(
  getCustomerFilters,
  (filter) => filter.customerCountry,
);

export const getCustomerStatus = createSelector(
  getCustomerFilters,
  (filter) => filter.customerStatus,
);

const { actions, reducer } = customerFilterSlice;

export const {
  customerFilters,
  customerFirstName,
  customerLastName,
  customerPhone,
  customerStatus,
  customerCountry,
  resetCustomerFilters,
} = actions;

export default reducer;
