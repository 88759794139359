import Api from './http-service';
import urls from '../config/constants/urls';
import { toCamelCaseObject, toSnakeCaseObject } from '../helpers/utils';
import {
  buildPathStringParams,
  buildPathWithKeyAndParams,
  buildPathWithParams,
} from '../helpers/url';
import { EmptyPagination, GenericList, Pagination, RPagination, Total } from '../types/services';
import { BalanceReports } from './balance-report-service';

export interface RMerchant {
  id: number;
  name: string;
}

export interface RMerchantLogin {
  merchantId: number;
  merchantName: string;
  merchantLive: boolean;
  scopes: [];
}

export interface Merchant {
  id: number;
  name: string;
}

export interface Merchants {
  merchants: Merchant[];
}

export interface MerchantRequest {
  id: number;
}

export const EmptyMerchant: Merchant = {
  id: -1,
  name: '',
};

export const EmptyMerchants: Merchants = {
  merchants: [
    {
      id: 0,
      name: '',
    },
  ],
};

export interface MerchantInternal {
  id: number;
  name: string;
  email: string;
  website: string;
  status: string;
  legallyApproved: boolean;
  login?: string;
}

export interface MerchantPartnerAccountManager {
  active: string;
  endDate: string;
  merchantId: string;
  merchantPartnerAccountManagerId: string;
  partnerAccountManagerId: string;
  startDate: string;
}

export interface MerchantSalesAccountManager {
  active: string;
  endDate: string;
  merchantId: string;
  merchantSalesAccountManagerId: string;
  salesAccountManagerId: string;
  startDate: string;
}

export interface MerchantDetail {
  config: {
    highRisk: boolean;
    legalActive: boolean;
    legalStatus: string;
    observed: boolean;
    settlementPeriod: number;
    status: string;
    merchantCashoutActive: boolean;
    merchantCashoutUploadFileActive: boolean;
    legalPartnerId: number;
    payout: boolean;
  };
  description: {
    address: string;
    averagePayment: string;
    businessType: string;
    city: string;
    country: string;
    industry: string;
    legalName: string;
    taxId: string;
    zipCode: string;
  };
  email: string;
  financialInfo: AccountInfo;
  intermediaryFinancialInfo: AccountInfo;
  id: number;
  name: string;
  internalName: string;
  representative: string;
  status: string;
  url: string;
  regionId: number;
  changeRegionEnable: boolean;

  merchantPartnerAccountManager: MerchantPartnerAccountManager;
  merchantSalesAccountManager: MerchantSalesAccountManager;
  businessUnits: [{ id: number }];
  holding: string;
  merchantCategoryCodeId: number;
  merchantCompanyContractId: number;
}

export const EmptyDetailMerchants: MerchantDetail = {
  config: {
    highRisk: false,
    legalActive: false,
    legalStatus: '',
    observed: false,
    settlementPeriod: 0,
    merchantCashoutActive: false,
    merchantCashoutUploadFileActive: false,
    status: '',
    legalPartnerId: -1,
    payout: false,
  },
  description: {
    address: '',
    averagePayment: '',
    businessType: '',
    city: '',
    country: '',
    industry: '',
    legalName: '',
    taxId: '',
    zipCode: '',
  },
  email: '',
  financialInfo: {
    bank: '',
    bankAccount: '',
    bankBeneficiary: '',
    bankCountry: '',
    bankSwift: '',
    comments: '',
  },
  intermediaryFinancialInfo: {
    bank: '',
    bankAccount: '',
    bankCountry: '',
    bankSwift: '',
    comments: '',
  },
  id: 0,
  name: '',
  internalName: '',
  representative: '',
  status: '',
  url: '',
  regionId: 0,
  changeRegionEnable: false,
  merchantPartnerAccountManager: {
    active: '',
    endDate: '',
    merchantId: '',
    merchantPartnerAccountManagerId: '',
    partnerAccountManagerId: '',
    startDate: '',
  },
  merchantSalesAccountManager: {
    active: '',
    endDate: '',
    merchantId: '',
    merchantSalesAccountManagerId: '',
    salesAccountManagerId: '',
    startDate: '',
  },
  businessUnits: [{ id: 0 }],
  holding: '',
  merchantCategoryCodeId: 0,
  merchantCompanyContractId: 0,
};

export interface RMerchantFee {
  country_iso?: string;
  region_iso?: string;
  payment_method_code?: string;
  minimum_volume: number;
  fee_percent: number;
  fee_transaction: number;
  fee_minimum: number;
  application_date: string;
  active: boolean;
  edit_mode?: boolean;
  flat_fee?: boolean;
  pay_by_astropay?: boolean;
  id: number;
}

export interface MerchantFee {
  countryIso?: string;
  regionIso?: string;
  paymentMethodCode?: string;
  minimumVolume: number;
  feePercent: number;
  feeTransaction: number;
  feeMinimum: number;
  applicationDate: string;
  active: boolean;
  editMode?: boolean;
  flatFee: boolean;
  payByAstropay?: boolean;
  id: number;
}

export interface MerchantFees {
  regions: MerchantFee[];
  countries: MerchantFee[];
}

export interface RMerchantPaymentMethodFee {
  id: number;
  country_iso: string;
  payment_method_code?: string;
  fee_percent: number;
  fee_transaction: number;
  fee_min: number;
  application_date?: string;
  pay_by_astropay: boolean;
  status: boolean;
  edit_mode?: boolean;
  minimum_volume: number;
  flat_fee?: boolean;
}

export interface MerchantPaymentMethodFee {
  id: number;
  countryIso: string;
  paymentMethodCode?: string;
  feePercent: number;
  feeTransaction: number;
  feeMin: number;
  applicationDate: string;
  payByAstropay: boolean;
  status: boolean;
  editMode?: boolean;
  minimumVolume: number;
  flatFee: boolean;
}

export interface RMerchantPaymentMethodFees {
  merchant_payment_method_fee: RMerchantPaymentMethodFee[];
}

export interface MerchantPaymentMethodFees {
  merchantPaymentMethodFee: MerchantPaymentMethodFee[];
}

export interface CashoutFee {
  fee: number;
  commission: number;
}

export const EmptyCashoutFee: CashoutFee = {
  fee: 0,
  commission: 0,
};

export interface MerchantFX {
  applicationDate?: string;
  currencyBase: string;
  currencyTarget: string;
  xrIn: number;
  xrOut: number;
  enabled: boolean;
  editMode?: boolean;
}

export interface MerchantFxs {
  fxs: MerchantFX[];
}

export interface AccountManagerType {
  id: number;
  name: string;
  email: string;
}

export interface AccountManagerInfoDto {
  accountManagerInfoDto: AccountManagerType[];
}

export interface BusinessUnitType {
  id: number;
  name: string;
}

export interface MerchantsCompanyContractList {
  merchantsCompanyContract: MerchantsCompanyContract[];
}

export interface MerchantsCompanyContract {
  merchantCompanyContractId: number;
  companyName: string;
  accountingCurrency: string;
}

export interface BusinessUnits {
  businessUnits: BusinessUnitType[];
}

export interface MerchantHoldingType {
  merchantHoldingId: number;
  name: string;
}

export interface MerchantHoldings {
  holdingList: MerchantHoldingType[];
}

export interface UpdateMerchantBank {
  mainBank: {
    bankCountry: string;
    bank: string;
    bankSwift: string;
    bankAccount: string;
    comments: string;
    bankBeneficiary: string;
  };
  intermediaryBank: {
    bankCountry: string;
    bank: string;
    bankSwift: string;
    bankAccount: string;
    comments: string;
  };
}

export interface RMerchantCategoryCode {
  merchant_category_code_id: number;
  mcc_code: number;
  description: string;
}

export interface RMerchantsCategoryCode {
  merchants_category_code: RMerchantCategoryCode[];
}

export interface MerchantCategoryCode {
  merchantCategoryCodeId: number;
  mccCode: number;
  description: string;
}

export interface MerchantsCategoryCode {
  merchantsCategoryCode: MerchantCategoryCode[];
}

export interface AccountInfo {
  bankCountry: string;
  bank: string;
  bankSwift: string;
  bankAccount: string;
  comments: string;
  bankBeneficiary?: string;
}

export const emptyMainBank: AccountInfo = {
  bank: '',
  bankAccount: '',
  bankBeneficiary: '',
  bankCountry: '',
  bankSwift: '',
  comments: '',
};

export const emptyIntermediaryBank: AccountInfo = {
  bankCountry: '',
  bank: '',
  bankSwift: '',
  bankAccount: '',
  comments: '',
};

export interface MerchantInformation {
  email: string;
  name: string;
}

export interface CashoutLimits {
  id?: number;
  merchantId?: number;
  transactionLimit: number;
  dailyLimit: number;
  monthlyLimit: number;
}

interface MerchantBusinessForm {
  legalName: string;
  commercialName: string;
  regionId: number;
  country: string;
  industry: number;
  merchantCategoryCodeId: number;
}

export type MerchantsInternal = GenericList<MerchantInternal, Pagination, Total>;

export default class MerchantService {
  static getMerchants = async (status?: boolean) => {
    if (status) {
      return MerchantService.getMerchantsByStatus('status=ACTIVE');
    } else {
      return MerchantService.getAllMerchants();
    }
  };

  static getMerchantsByStatus = async (status: string) => {
    try {
      const response = await Api.get<RMerchant>(
        buildPathStringParams(urls.getMerchantsStatus, status),
      );
      return toCamelCaseObject<Merchants>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getAllMerchants = async () => {
    try {
      const response = await Api.get<RMerchant>(urls.merchants);
      return toCamelCaseObject<Merchants>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getMerchantDetails = async (merchantId: string) => {
    try {
      const response = await Api.get<RMerchant>(
        buildPathWithParams(urls.getMerchantsDetail, { merchantId }),
      );
      return toCamelCaseObject<MerchantDetail>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantConfig = async (
    merchantId: string,
    merchantConfig: MerchantDetail['config'],
  ) => {
    try {
      const response = await Api.put<MerchantDetail['config']>(
        buildPathWithParams(urls.updateMerchantConfig, { merchantId }),
        toSnakeCaseObject(merchantConfig),
      );
      return { response };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantBusiness = async (data: MerchantBusinessForm) => {
    try {
      const response = await Api.put<string>(urls.updateMerchantBusiness, toSnakeCaseObject(data));
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static loginMerchant = async (id: number) => {
    const body = { merchant_id: id };

    const response = await Api.post<RMerchantLogin>(urls.merchants, body);
    return toCamelCaseObject<RMerchantLogin>(response);
  };

  static getMerchantsInternal = (params: string) => async (page?: RPagination) => {
    try {
      const response = await Api.get<MerchantInternal>(
        buildPathStringParams(urls.merchantsInternal, params),
        page,
      );
      return toCamelCaseObject<MerchantsInternal>(response);
    } catch (e) {
      return { data: [], page: EmptyPagination };
    }
  };

  //
  // CONFIG
  //

  static updateMerchantRegion = async (merchantId: string, regionId: number) => {
    try {
      const response = await Api.put<RMerchantFee>(
        buildPathWithParams(urls.updateMerchantRegion, { merchantId }),
        { region_id: regionId },
      );
      return toCamelCaseObject<RMerchantFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getPartnerAccountManagers = async () => {
    try {
      const response = await Api.get<AccountManagerInfoDto>(urls.getPartnerAccountManagers);
      return toCamelCaseObject<AccountManagerInfoDto>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updatePartnerAccountManager = async (merchantId: string, accountManager: number) => {
    try {
      const response = await Api.put<AccountManagerType>(
        buildPathWithParams(urls.updatePartnerAccountManager, { merchantId }),
        { account_manager_id: accountManager },
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getSalesAccountManagers = async () => {
    try {
      const response = await Api.get<AccountManagerInfoDto>(urls.getSalesAccountManagers);
      return toCamelCaseObject<AccountManagerInfoDto>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateSalesAccountManager = async (
    merchantId: string,
    accountManager: number,
    endDate: string,
  ) => {
    try {
      const response = await Api.put<AccountManagerType>(
        buildPathWithParams(urls.updateSalesAccountManager, { merchantId, endDate }),
        { account_manager_id: accountManager, end_date: endDate },
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getBusinessUnits = async () => {
    try {
      const response = await Api.get<BusinessUnits>(urls.getBusinessUnits);
      return toCamelCaseObject<BusinessUnits>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateBusinessUnit = async (merchantId: string, businessUnitId: number[]) => {
    try {
      const response = await Api.put<BusinessUnits>(
        buildPathWithParams(urls.updateBusinessUnit, { merchantId }),
        { business_unit_ids: businessUnitId },
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getMerchantHolding = async () => {
    try {
      const response = await Api.get<string>(urls.getMerchantHolding);
      return toCamelCaseObject<MerchantHoldings>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantHolding = async (merchantId: string, merchantHolding: string) => {
    try {
      const response = await Api.put<string>(
        buildPathWithParams(urls.updateMerchantHolding, { merchantId }),
        { holding: merchantHolding },
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantMainBank = async (merchantId: string, accountInfo: AccountInfo) => {
    try {
      const response = await Api.put<string>(
        buildPathWithParams(urls.updateMainBank, { merchantId }),
        toSnakeCaseObject(accountInfo),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantIntermediaryBank = async (merchantId: string, accountInfo: AccountInfo) => {
    try {
      const response = await Api.put<string>(
        buildPathWithParams(urls.updateIntermediaryBank, { merchantId }),
        toSnakeCaseObject(accountInfo),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static deleteMerchantIntermediaryBank = async (merchantId: string) => {
    try {
      const response = await Api.delete(
        buildPathWithParams(urls.updateIntermediaryBank, { merchantId }),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static updateMerchantInfo = async (merchantId: string, info: any) => {
    try {
      const response = await Api.put<string>(
        buildPathWithParams(urls.updateMerchantInformation, { merchantId }),
        toSnakeCaseObject(info),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static updateMerchantDescription = async (merchantId: string, info: any) => {
    try {
      const response = await Api.put<string>(
        buildPathWithParams(urls.updateMerchantDescription, { merchantId }),
        toSnakeCaseObject(info),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  //
  // DEPOSIT FEE
  //

  static getMerchantFees = async (merchantId: string) => {
    try {
      const response = await Api.get<MerchantFees>(
        buildPathWithParams(urls.getMerchantFees, { merchantId }),
      );
      return toCamelCaseObject<MerchantFees>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getMerchantPaymentMethodFees = async (merchantId: string) => {
    try {
      const response = await Api.get<RMerchantPaymentMethodFees>(
        buildPathWithParams(urls.getMerchantPaymentMethodFees, { merchantId }),
      );
      return toCamelCaseObject<MerchantPaymentMethodFees>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static saveMerchantFee = async (merchantId: string, merchantFee: RMerchantFee) => {
    try {
      const response = await Api.post<RMerchantFee>(
        buildPathWithParams(urls.saveMerchantFee, { merchantId }),
        merchantFee,
      );
      return toCamelCaseObject<RMerchantFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static saveMerchantPaymentMethodFee = async (
    merchantId: string,
    merchantFee: RMerchantPaymentMethodFee,
  ) => {
    try {
      const response = await Api.post<RMerchantPaymentMethodFee>(
        buildPathWithParams(urls.saveMerchantPaymentMethodFee, { merchantId }),
        merchantFee,
      );
      return toCamelCaseObject<RMerchantPaymentMethodFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantFee = async (merchantFee: RMerchantFee) => {
    try {
      const response = await Api.put<RMerchantFee>(urls.updateMerchantFee, merchantFee);
      return toCamelCaseObject<RMerchantFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantPaymentMethodFee = async (merchantFee: RMerchantPaymentMethodFee) => {
    try {
      const response = await Api.put<RMerchantPaymentMethodFee>(
        urls.updateMerchantPaymentMethodFee,
        merchantFee,
      );
      return toCamelCaseObject<RMerchantPaymentMethodFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static deleteMerchantFee = async (merchantId: string, id: number, flatFee: boolean) => {
    try {
      const response = await Api.delete(
        buildPathWithParams(urls.deleteMerchantFee, { merchantId }),
        {
          id: id,
          flat_fee: flatFee,
        },
      );
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static deleteMerchantPaymentMethodFee = async (
    merchantId: string,
    id: number,
    flatFee: boolean,
  ) => {
    try {
      const response = await Api.delete(
        buildPathWithParams(urls.deleteMerchantPaymentMethodFee, { merchantId }),
        {
          id: id,
          flat_fee: flatFee,
        },
      );
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  //
  // CASHOUT FEE
  //

  static getCashoutFees = async (merchantId: string) => {
    try {
      const response = await Api.get<CashoutFee>(
        buildPathWithParams(urls.getCashoutFee, { merchantId }),
      );
      return toCamelCaseObject<CashoutFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateCashoutFees = async (merchantId: string, cashoutFee: CashoutFee) => {
    try {
      const response = await Api.put<CashoutFee>(
        buildPathWithParams(urls.getCashoutFee, { merchantId }),
        cashoutFee,
      );
      return toCamelCaseObject<CashoutFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static activeCashout = async (merchantId: string) => {
    try {
      const response = await Api.post<string>(
        buildPathWithParams(urls.activateCashout, { merchantId }),
      );
      return toCamelCaseObject<CashoutFee>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  //
  // FX
  //

  static getFxs = async (merchantId: string) => {
    try {
      const response = await Api.get<MerchantFxs>(buildPathWithParams(urls.getFxs, { merchantId }));
      return toCamelCaseObject<MerchantFxs>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static saveFX = async (merchantId: string, merchantFX: MerchantFX) => {
    try {
      const response = await Api.post<MerchantFX>(
        buildPathWithParams(urls.saveFx, { merchantId }),
        toSnakeCaseObject(merchantFX),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateFX = async (merchantId: string, merchantFX: MerchantFX) => {
    try {
      const response = await Api.put<CashoutFee>(
        buildPathWithParams(urls.updateFx, { merchantId }),
        toSnakeCaseObject(merchantFX),
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static deleteFX = async (merchantId: string, merchantFX: MerchantFX) => {
    try {
      const response = await Api.delete(buildPathWithParams(urls.deleteFx, { merchantId }), {
        currency_base: merchantFX.currencyBase,
        currency_target: merchantFX.currencyTarget,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  //
  // BALANCE REPORT
  //
  static getMerchantBalanceReport = (merchantId: string, params: string) => async (
    page?: RPagination,
  ) => {
    try {
      const response = await Api.get<BalanceReports>(
        buildPathWithKeyAndParams(urls.getMerchantBalanceReport, { merchantId }, params),
        page,
      );
      return toCamelCaseObject<BalanceReports>(response);
    } catch {
      return { data: [], page: EmptyPagination };
    }
  };

  static getMerchantCategoryCodes = async () => {
    try {
      const response = await Api.get<RMerchantsCategoryCode>(urls.getMerchantCategoryCode);
      return toCamelCaseObject<MerchantsCategoryCode>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantCategoryCode = async (
    merchantId: string,
    merchantCategoryCodeId: number,
  ) => {
    try {
      const response = await Api.put<RMerchantCategoryCode>(urls.updateMerchantCategoryCode, {
        merchant_id: merchantId,
        merchant_category_code_id: merchantCategoryCodeId,
      });
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateMerchantCompanyContractor = async (
    merchantId: string,
    companyContractorId: number,
  ) => {
    try {
      const response = await Api.put(
        buildPathWithParams(urls.updateMerchantCompanyContractor, { merchantId }),
        { merchant_company_contract_id: companyContractorId },
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static getCompanyContractorList = async () => {
    try {
      const response = await Api.get<MerchantsCompanyContractList>(urls.getCompanyContractorList);
      return toCamelCaseObject<MerchantsCompanyContractList>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // LIMITS
  static getCashoutLimits = async (merchantId: string) => {
    try {
      const response = await Api.get<string[]>(
        buildPathWithParams(urls.getCashoutLimits, {
          merchantId,
        }),
      );
      return toCamelCaseObject<CashoutLimits>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static updateCashoutLimits = async (merchantId: string, values: CashoutLimits) => {
    try {
      const response = await Api.put<string[]>(
        buildPathWithParams(urls.updateCashoutLimits, {
          merchantId,
        }),
        {
          transaction_limit: values.transactionLimit,
          daily_limit: values.dailyLimit,
          monthly_limit: values.monthlyLimit,
        },
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
