import React from 'react';
import { createBrowserHistory } from 'history';

import RoutePaths from '../config/constants/route-paths';
import { RouteToRender } from '../config/constants/routes.types';
import { role } from '../config/constants/roles';

import getDashboardRoutes from './modules/dashboard-config';
import getMerchantRoutes from './modules/merchant-config';
import getDepositRoutes from './modules/deposit-config';
import getCashoutRoutes from './modules/cashout-config';
import getFullgatewayCashoutsRoutes from './modules/fullgateway-cashouts-config';
import getFullgatewayDepositRoutes from './modules/fullgateway-deposit-config';
import getSettlementRoutes from './modules/settlement-config';
import getBalanceReportRoutes from './modules/balance-report-config';
import getPaymentMethodRoutes from './modules/payment-method-config';
import getUsersRoutes from './modules/users-config';
import getSendMoneyRoutes from './modules/send-money-config';
import getCredentialsRoutes from './modules/credentials-config';
import getCreditDebitNotesRoutes from './modules/credit-debit-notes-config';
import getMerchantAccountRoutes from './modules/merchant-account-config';
import getCollaboratorRoutes from './modules/collaborators-config';
import getTransactionPayoutRoutes from './modules/transaction-payout-config';
import getPayRoutes from './modules/pay-config';
import getCustomerRoutes from './modules/customer-config';
import getTransactionPayinRoutes from './modules/transaction-payin-config';
import getPOSRoutes from './modules/pos-config';
import getUploadCashoutRoutes from './modules/upload-cashout-config';
import getPaymentLinkRoutes from './modules/payment-link-config';

export const history = createBrowserHistory({ forceRefresh: false });

export const getRoutes = (scopes: string[]) => {
  const routes: RouteToRender[] = [
    {
      path: RoutePaths.login,
      component: React.lazy(() => import('../pages/session/login/login-page')),
    },
    {
      path: RoutePaths.internalLogin,
      component: React.lazy(() => import('../pages/session/internal-login/internal-login')),
    },
    {
      path: RoutePaths.signUp,
      component: React.lazy(() => import('../pages/session/sign-up/sign-up-page')),
    },
    {
      path: RoutePaths.signUpV1,
      component: React.lazy(() => import('../pages/session/sign-up/redirect-signup')),
    },
    {
      path: RoutePaths.confirmEmail,
      component: React.lazy(() => import('../pages/session/sign-up/confirm-email-process')),
    },
    {
      path: RoutePaths.confirmEmailBusiness,
      component: React.lazy(
        () => import('../pages/session/sign-up-business/confirm-email-business'),
      ),
    },
    {
      path: RoutePaths.setPassword,
      component: React.lazy(() => import('../pages/session/sign-up/components/go-live-password')),
    },
    {
      path: RoutePaths.newPassword,
      component: React.lazy(() => import('../pages/session/sign-up/components/new-password')),
    },
    {
      path: RoutePaths.forgotPassword,
      component: React.lazy(() => import('../pages/session/forgot-password/forgot-password-page')),
    },
    {
      path: RoutePaths.signUpBusiness,
      component: React.lazy(
        () => import('../pages/session/sign-up-business/sign-up-business-page'),
      ),
    },
    {
      requiresAuthentication: true,
      items: [
        {
          path: RoutePaths.selectMerchant,
          component: React.lazy(() => import('../pages/select-merchant/select-merchant')),
          roles: [role.merchant],
          hasPermission: true,
        },
        ...getMerchantRoutes(),
        ...getDashboardRoutes(),
        ...getDepositRoutes(scopes),
        ...getCashoutRoutes(scopes),
        ...getFullgatewayCashoutsRoutes(scopes),
        ...getFullgatewayDepositRoutes(scopes),
        ...getSettlementRoutes(scopes),
        ...getBalanceReportRoutes(scopes),
        ...getPaymentMethodRoutes(scopes),
        ...getUsersRoutes(scopes),
        ...getSendMoneyRoutes(scopes),
        ...getCredentialsRoutes(scopes),
        ...getCreditDebitNotesRoutes(scopes),
        ...getMerchantAccountRoutes(),
        ...getCollaboratorRoutes(scopes),
        ...getTransactionPayoutRoutes(scopes),
        ...getPayRoutes(scopes),
        ...getCustomerRoutes(scopes),
        ...getTransactionPayinRoutes(scopes),
        ...getPOSRoutes(scopes),
        ...getUploadCashoutRoutes(scopes),
        ...getPaymentLinkRoutes(scopes),
      ],
    },
  ];

  return routes;
};
