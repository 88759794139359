import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { MerchantDepositFilterState } from './types';

const initialState: MerchantDepositFilterState = {
  paymentMethod: {
    paymentMethodCode: '',
    country: '',
    payByAstropay: '',
  },
  country: '',
  region: '',
};

export const merchantPaymentMethodFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    paymentMethodCode: (state, action: PayloadAction<string>) => {
      state.paymentMethod.paymentMethodCode = action.payload;
    },
    paymentMethodCountry: (state, action: PayloadAction<string>) => {
      state.paymentMethod.country = action.payload;
    },
    depositCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    depositRegion: (state, action: PayloadAction<string>) => {
      state.region = action.payload;
    },
    paymentMethodPayByAstropay: (state, action: PayloadAction<string>) => {
      state.paymentMethod.payByAstropay = action.payload;
    },
    resetPaymentMethodFilters: (state) => {
      state.paymentMethod.paymentMethodCode = initialState.paymentMethod.paymentMethodCode;
      state.paymentMethod.country = initialState.paymentMethod.country;
      state.paymentMethod.payByAstropay = initialState.paymentMethod.payByAstropay;
    },
  },
});

// Selectors
const getPaymentMethodFilters = (state: RootState): MerchantDepositFilterState =>
  state.merchantDepositFilterReducer;
export const getPaymentsMethodFilters = createSelector(
  getPaymentMethodFilters,
  ({ paymentMethod }) => ({
    country: paymentMethod.country,
    paymentMethodCode: paymentMethod.paymentMethodCode,
    payByAstropay: paymentMethod.payByAstropay,
  }),
);
export const getPaymentMethodCode = createSelector(
  getPaymentMethodFilters,
  (filter) => filter.paymentMethod.paymentMethodCode,
);
export const getCountry = createSelector(
  getPaymentMethodFilters,
  (filter) => filter.paymentMethod.country,
);
export const getDepositCountry = createSelector(
  getPaymentMethodFilters,
  (filter) => filter.country,
);
export const getDepositRegion = createSelector(getPaymentMethodFilters, (filter) => filter.region);
export const getPayByAstropay = createSelector(
  getPaymentMethodFilters,
  (filter) => filter.paymentMethod.payByAstropay,
);

const { actions, reducer } = merchantPaymentMethodFilterSlice;

export const {
  paymentMethodCode,
  paymentMethodCountry,
  paymentMethodPayByAstropay,
  resetPaymentMethodFilters,
  depositCountry,
  depositRegion,
} = actions;

export default reducer;
