import { createSelector } from 'reselect';
import { FilterState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const initialState: FilterState = {
  forceReloadByFilter: false,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setForceReloadByFilter: (state, action: PayloadAction<boolean>) => {
      state.forceReloadByFilter = action.payload;
    },
  },
});

// Selectors
const getFilter = (state: RootState): FilterState => state.filters;
export const getForceReloadByFilter = createSelector(
  getFilter,
  (filters) => filters.forceReloadByFilter,
);
const { actions, reducer } = filtersSlice;

export const { setForceReloadByFilter } = actions;

export default reducer;
