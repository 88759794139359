import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getDashboardMenu = () => {
  const dashboard: SubMenuItemType[] = [
    {
      path: RoutePaths.dashboard,
      icon: 'tachometer-alt',
      title: 'nav_dashboard',
      roles: [role.merchant],
      hasPermission: true,
      id: 'mp-main-dashboard-action',
    },
  ];
  return dashboard;
};

const getDashboardRoutes = () => {
  const DashboardRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.dashboard,
      component: React.lazy(() => import('../../pages/dashboard/dashboard-page')),
      roles: [role.merchant],
      hasPermission: true,
    },
  ];
  return DashboardRoutes;
};

export default getDashboardRoutes;
