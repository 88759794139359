import React, { createContext, useContext, useMemo } from 'react';
import themeTypes from './config/constants/theme';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './styles/theme/material-theme';
import themeDark from './styles/theme/material-theme-dark';
import { Helmet } from 'react-helmet';

type ThemeContext = {
  template: string;
  isDarkMode: boolean;
  toggleCheckedDarkMode: () => void;
};

const ThemeContext = createContext<ThemeContext>({
  template: themeTypes.ASTROPAY,
  toggleCheckedDarkMode: () => null,
  isDarkMode: false,
});

type ThemeProviderProps = { children: React.ReactNode };

function PanelThemeProvider(props: ThemeProviderProps) {
  const { children } = props;

  const isDarkMode = localStorage.getItem('darkMode') === 'true';

  const template = themeTypes.ASTROPAY;

  const toggleCheckedDarkMode = () => {
    localStorage.setItem('darkMode', isDarkMode ? 'false' : 'true');
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const combinedTheme = useMemo<ThemeContext>(
    () => ({
      template,
      toggleCheckedDarkMode,
      isDarkMode,
    }),
    [template],
  );

  return (
    <ThemeContext.Provider value={combinedTheme}>
      <Helmet>
        <link rel="icon" href={'/favicon.png'} />
        <link rel="apple-touch-icon" href={'/favicon.png'} />
      </Helmet>
      <MuiThemeProvider theme={isDarkMode ? themeDark : theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

function useMerchantTheme(): ThemeContext {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useMerchantTheme must be used within a PanelThemeProvider');
  }
  return context;
}

export { useMerchantTheme, PanelThemeProvider };
