import { SubMenuItemType } from './menu.types';

export const hasScopePermission = (scope: string) => {
  let permission = false;
  const userScopesString = localStorage.getItem('persist:__MERCHANT_PANEL__SESSION__');
  const userScopes = userScopesString ? JSON.parse(userScopesString).user : null;
  if (userScopes) {
    permission = JSON.parse(userScopes).scopes.includes(scope);
    return permission;
  }
  return permission;
};

export const hasMenuPermission = (route: SubMenuItemType) => {
  let hasPermissionByRole = false;

  const hasPermissionByScope = route?.hasPermission || false;
  const userStorage = localStorage.getItem('persist:__MERCHANT_PANEL__SESSION__');
  const user = userStorage ? JSON.parse(userStorage).user : null;

  if (user) {
    const userRole = JSON.parse(user).role;
    hasPermissionByRole = route?.roles.includes(userRole);
    return hasPermissionByScope && hasPermissionByRole;
  }

  return hasPermissionByRole;
};

export const hasPermission = (userScopes: string[] | undefined, scope: string) => {
  let permission = false;
  if (userScopes) {
    permission = userScopes.includes(scope);
    return permission;
  }

  return permission;
};

export const hasSomeScopePermission = (userScopes: string[], scopes: string[]) => {
  let permission = false;
  const allowedScopes = userScopes.filter((userScope) =>
    scopes.some((scope) => scope === userScope),
  );

  if (allowedScopes.length) {
    return (permission = true);
  }
  return permission;
};

const scopes = {
  transactionsReports: 'transactions_reports',
  fullgatewayReports: 'fullgateway_reports',
  transactionsDetails: 'transactions_details',
  settlements: 'settlements',
  merchantAvailableBalance: 'merchant_is_total/available_balance',
  paymentMethods: 'payment_methods',
  manageUsers: 'manage_users',
  sendMoney: 'send_money',
  credentials: 'credentials',
  creditDebitNote: 'credit_debit_note',
  uploadCashout: 'upload_cashout',
  payroll: 'payroll',
  payins: 'payins',
  manageScopes: 'manage_scopes',
};

export default scopes;
