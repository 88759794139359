import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';
import { faQrcode } from '@fortawesome/pro-regular-svg-icons';

export const getPOSMenu = (userScopes: string[]) => {
  const posMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.pos,
      icon: 'arrow-right',
      fullIcon: faQrcode,
      title: 'points_of_sale',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
      id: 'mp-payins-pos-action',
    },
  ];

  return posMenu;
};

const getPOSRoutes = (userScopes: string[]) => {
  const PayRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.pos,
      component: React.lazy(() => import('../../pages/pos/pos-page')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
    {
      path: RoutePaths.posDetail,
      component: React.lazy(() => import('../../pages/pos/pos-detail/pos-detail')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
  ];

  return PayRoutes;
};

export default getPOSRoutes;
