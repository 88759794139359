import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getPayMenu = (userScopes: string[]) => {
  const payMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.payments,
      icon: 'money-bill',
      title: 'pay',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
      id: 'mp-payouts-pay-action',
    },
  ];

  return payMenu;
};

const getPayRoutes = (userScopes: string[]) => {
  const PayRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.payments,
      component: React.lazy(() => import('../../pages/pay/payments')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
    },
  ];

  return PayRoutes;
};

export default getPayRoutes;
