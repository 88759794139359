import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { DepositFilterState } from './types';
import { dateByDefault, dateWeekByDefault } from '../../../config/constants/date-formats';

const initialState: DepositFilterState = {
  depositFilters: dateByDefault,
  depositDates: dateByDefault,
  depositId: '',
  depositReference: '',
  depositCountry: '',
  depositStatus: '',
  depositMerchant: '',
  depositOnlyActives: false,
  depositMerchantUserId: '',
};

export const depositFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    depositFilters: (state, action: PayloadAction<string>) => {
      state.depositFilters = action.payload;
    },
    depositDates: (state, action: PayloadAction<string>) => {
      state.depositDates = action.payload;
    },
    depositId: (state, action: PayloadAction<string>) => {
      state.depositId = action.payload;
    },
    depositReference: (state, action: PayloadAction<string>) => {
      state.depositReference = action.payload;
    },
    depositCountry: (state, action: PayloadAction<string>) => {
      state.depositCountry = action.payload;
    },
    depositStatus: (state, action: PayloadAction<string>) => {
      state.depositStatus = action.payload;
    },
    depositMerchant: (state, action: PayloadAction<string>) => {
      state.depositMerchant = action.payload;
    },
    depositOnlyActives: (state, action: PayloadAction<boolean>) => {
      state.depositOnlyActives = action.payload;
    },
    depositMerchantUserId: (state, action: PayloadAction<string>) => {
      state.depositMerchantUserId = action.payload;
    },
    resetDepositFilters: (state) => {
      state.depositFilters = initialState.depositFilters;
      state.depositDates = initialState.depositDates;
      state.depositId = initialState.depositId;
      state.depositReference = initialState.depositReference;
      state.depositCountry = initialState.depositCountry;
      state.depositStatus = initialState.depositStatus;
      state.depositMerchant = initialState.depositMerchant;
      state.depositOnlyActives = initialState.depositOnlyActives;
      state.depositMerchantUserId = initialState.depositMerchant;
    },
    initDepositMerchantFilters: (state, action: PayloadAction<string>) => {
      state.depositMerchant = `&merchant=${action.payload}`;
      state.depositFilters = `${dateWeekByDefault}&merchant=${action.payload}`;
      state.depositDates = dateWeekByDefault;
    },
  },
});

// Selectors
const getDepositFilters = (state: RootState): DepositFilterState => state.depositFilters;
export const getDepositsFilters = createSelector(
  getDepositFilters,
  (filter) => filter.depositFilters,
);
export const getDepositDates = createSelector(getDepositFilters, (filter) => filter.depositDates);
export const getDepositId = createSelector(getDepositFilters, (filter) => filter.depositId);
export const getDepositReference = createSelector(
  getDepositFilters,
  (filter) => filter.depositReference,
);
export const getDepositCountry = createSelector(
  getDepositFilters,
  (filter) => filter.depositCountry,
);
export const getDepositStatus = createSelector(getDepositFilters, (filter) => filter.depositStatus);
export const getDepositMerchant = createSelector(
  getDepositFilters,
  (filter) => filter.depositMerchant,
);
export const getDepositOnlyActives = createSelector(
  getDepositFilters,
  (filter) => filter.depositOnlyActives,
);
export const getDepositMerchantUserId = createSelector(
  getDepositFilters,
  (filter) => filter.depositMerchantUserId,
);

const { actions, reducer } = depositFilterSlice;

export const {
  depositFilters,
  depositDates,
  depositId,
  depositReference,
  depositCountry,
  depositStatus,
  depositMerchant,
  depositOnlyActives,
  depositMerchantUserId,
  resetDepositFilters,
  initDepositMerchantFilters,
} = actions;

export default reducer;
