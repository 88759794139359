import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getSendMoneyMenu = (userScopes: string[]) => {
  const sendMoneyMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.sendMoney,
      icon: 'dollar-sign',
      title: 'send_money',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.sendMoney),
      id: 'mp-main-send-money-action',
    },
  ];

  return sendMoneyMenu;
};

const getSendMoneyRoutes = (userScopes: string[]) => {
  const SendMoneyRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.sendMoney,
      component: React.lazy(() => import('../../pages/send-money/send-money')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.sendMoney),
    },
  ];

  return SendMoneyRoutes;
};

export default getSendMoneyRoutes;
