const urlBaseResources = process.env.REACT_APP_RESOURCES_BASE_URL;
const urlBaseResourcesMobile = process.env.REACT_APP_RESOURCES_MOBILE_BASE_URL;

export const resources = {
  banksImg: `${urlBaseResources}/img/banks/`,
  logos: `${urlBaseResources}/img/logos/`,
  flags: `${urlBaseResources}/img/flags/`,
  paymentMethods: `${urlBaseResources}/img/payment_methods/wide/`,
  paymentMethodsMobile: `${urlBaseResourcesMobile}/img/payment_methods/`,
  refunds: `${urlBaseResourcesMobile}/img/refund_methods/`,
  banks: `${urlBaseResourcesMobile}/img/banks/`,
  merchants: `${urlBaseResourcesMobile}/img/merchants/`,
  resellers: `${urlBaseResources}/img/resellers/`,
  brands: `${urlBaseResourcesMobile}/img/brands/`,
  regions: `${urlBaseResourcesMobile}/img/regions/`,
};

const urls = {
  // AUTH
  login: '/public/v1/login',
  astroLogin: '/public/v1/login/astro',
  signUp: '/public/v1/signup',
  signUpBusiness: '/public/v1/business/signup',
  confirmEmail: '/public/v1/confirmEmail',
  authMfa: '/public/v1/login/mfa',
  authLogin: '/auth/login',
  authLoginMfa: '/auth/login/mfa',
  createMfa: '/public/v1/mfa/create',
  activateMfa: '/public/v1/mfa/activate',
  deactivateMfa: '/public/v1/mfa/deactivate',
  forgotPass: '/public/v1/forgotPassword',
  recoveryPassword: '/public/v1/changePassword',
  logout: '/public/v1/logout',
  internalLogin: '/public/v1/login/okta',

  // Deposits
  getDeposits: `/public/v1/onetouch/deposits`,
  getDepositDetails: '/public/v1/onetouch/deposits/:depositId',
  exportDeposit: '/public/v1/onetouch/deposits/export/:format',

  exportFGDeposit: '/public/v1/onetouch/deposits/fullGateway/export/:format',
  resendDepositCallback: '/public/v1/onetouch/deposits/:id/notification',
  cancelDeposit: '/public/v1/onetouch/deposits/:id/cancel',
  depositSendEmailToSupport: '/public/v1/onetouch/deposits/:depositExternalId/sendEmailToSupport',
  refundDeposit: '/public/v1/onetouch/deposits/:uniqueId/refund',
  getFullGatewayDeposits: '/public/v1/onetouch/deposits/fullGateway',

  // Cashouts
  getCashouts: '/public/v1/onetouch/cashouts',
  getCashoutsDetails: '/public/v1/onetouch/cashouts/:cashoutId',
  downloadFGCashoutRecipt: '/public/v1/onetouch/cashouts/fullGateway/:cashoutId/receipt',
  exportCashout: '/public/v1/onetouch/cashouts/export/:format',

  exportFGCashout: '/public/v1/onetouch/cashouts/fullGateway/export/:format',
  downloadFGCashout: '/public/v1/onetouch/cashouts/fullGateway/:cashoutId/receipt',
  resendCashoutCallback: '/public/v1/onetouch/cashouts/:id/notification',
  cancelCashout: '/public/v1/onetouch/cashouts/:id/cancel',
  cashoutSendEmailToSupport: '/public/v1/onetouch/cashouts/:cashoutId/sendEmailToSupport',
  uploadCashout: '/public/v1/onetouch/cashouts/uploadCashout',
  processCashout: '/public/v1/onetouch/cashouts/processCashout',
  activateUploadCashout: '/public/v1/merchants/:merchantId/activate/config',
  deactivateUploadCashout: '/public/v1/merchants/:merchantId/deactivate/config',
  getFullGatewayCashouts: '/public/v1/onetouch/cashouts/fullGateway',

  // Merchants
  merchants: '/public/v1/login/merchants',
  getMerchantsStatus: '/public/v1/merchants/',
  getMerchantsByEmail: '/public/v1/merchants/by-email',
  merchantsInternal: '/public/v1/login/merchants/internal',
  getMerchantsDetail: '/public/v1/merchants/:merchantId',
  updateMerchantConfig: '/public/v1/merchants/:merchantId',
  updateMerchantBusiness: '/public/v1/merchants/business',
  getMerchantFees: '/public/v1/merchants/:merchantId/fees',
  getMerchantPaymentMethodFees: '/public/v1/merchants/:merchantId/payment/method/fee',
  saveMerchantFee: '/public/v1/merchants/:merchantId/fee',
  saveMerchantPaymentMethodFee: '/public/v1/merchants/:merchantId/payment/method/fee',
  updateMerchantFee: '/public/v1/merchants/fee',
  updateMerchantPaymentMethodFee: '/public/v1/merchants/payment/method/fee',
  deleteMerchantFee: '/public/v1/merchants/:merchantId/fee',
  deleteMerchantPaymentMethodFee: '/public/v1/merchants/:merchantId/payment/method/fee',
  getCashoutFee: '/public/v1/merchants/:merchantId/cashout/fee',
  updateCashoutFee: '/public/v1/merchants/:merchantId/cashout/fee',
  activateCashout: '/public/v1/merchants/:merchantId/cashout/activate',
  getFxs: '/public/v1/merchants/:merchantId/fx',
  saveFx: '/public/v1/merchants/:merchantId/fx',
  updateFx: '/public/v1/merchants/:merchantId/fx',
  deleteFx: '/public/v1/merchants/:merchantId/fx',
  updateMerchantRegion: '/public/v1/merchants/:merchantId/region',
  getPartnerAccountManagers: '/public/v1/merchants/partnerAccountManager',
  updatePartnerAccountManager: '/public/v1/merchants/:merchantId/partnerAccountManager',
  getSalesAccountManagers: '/public/v1/merchants/salesAccountManager',
  updateSalesAccountManager: '/public/v1/merchants/:merchantId/salesAccountManager',
  getBusinessUnits: '/public/v1/merchants/business/units',
  updateBusinessUnit: '/public/v1/merchants/:merchantId/business/units',
  getMerchantHolding: '/public/v1/merchants/holding',
  updateMerchantHolding: '/public/v1/merchants/:merchantId/holding',
  getMerchantBalanceReport: '/public/v1/merchants/:merchantId/balance/report',
  exportMerchantBalanceReport: '/public/v1/merchants/:merchantId/balance/report/export/:format',

  updateMainBank: '/public/v1/merchants/:merchantId/mainBank',
  updateIntermediaryBank: '/public/v1/merchants/:merchantId/intermediaryBank',
  updateMerchantCompanyContractor: '/public/v1/merchants/:merchantId/company/contract',
  getCompanyContractorList: '/public/v1/merchants/merchantCompanyContract',
  getCashoutLimits: '/public/v1/merchants/:merchantId/cashoutLimit',
  updateCashoutLimits: '/public/v1/merchants/:merchantId/cashoutLimit',

  // Merchant Profile
  getMerchantInfo: '/public/v1/merchants/profile',
  getMerchantFile: '/public/v1/merchants/profile/file',
  getBankDetails: '/public/v1/merchants/profile/bank',
  updateMerchantMainBank: '/public/v1/merchants/profile/mainBank',
  updateMerchantIntermediaryBank: '/public/v1/merchants/profile/intermediaryBank',
  getAccountManager: '/public/v1/merchants/profile/accountManager',
  sendEmailContact: '/public/v1/merchants/profile/sendEmail',

  // Settlement
  getSettlement: '/public/v1/settlement',
  createSettlement: '/public/v1/settlement',
  getSettlementDetail: '/public/v1/settlement/:id',
  updateSettlement: '/public/v1/settlement/:id',
  getSettlementBank: '/public/v1/settlement/bank',
  getAutomaticSettlement: '/public/v1/settlement/:merchantId/config',
  updateAutomaticSettlement: '/public/v1/settlement/:merchantId/config',
  deactivateAutomaticSettlement: '/public/v1/settlement/:merchantId/deactivate/config',
  activateAutomaticSettlement: '/public/v1/settlement/:merchantId/activate/config',
  exportSettlements: 'public/v1/settlement/export/:format',

  uploadSubSettlement: '/public/v1/subsettlement/upload',
  processSubSettlement: '/public/v1/subsettlement/process?mfa_code=:mfaCode',
  getSettlementAccount: '/public/v1/merchants/settlement/account',
  updateSettlementAccount: 'public/v1/merchants/:merchantId/settlement/bank/config',
  getSettlementConfig: '/public/v1/merchants/:merchantId/settlement/bank/config',

  // Payment Method
  getPaymentMethod: '/public/v1/paymentMethods',
  getPaymentMethodActive: '/public/v1/paymentMethods/active',
  exportPaymentMethod: '/public/v1/paymentMethods/export/:format',

  // Balance Report
  getBalanceReport: '/public/v1/balance/report',
  exportBalanceReport: '/public/v1/balance/report/export/:format',

  // General
  getCountries: '/public/v1/countries',
  getBalances: '/public/v1/balance',
  getMerchantBalance: '/public/v1/merchants/:merchantId/balance',
  getRegions: '/public/v1/regions',
  getCurrencies: '/public/v1/currencies',
  getCurrenciesByCountry: '/public/v1/countries/:countryIso/currency',
  getExchanges: '/public/v1/merchants/exchange',

  // SignUp Steppers
  uploadCertificate: '/public/v1/merchants/uploadCertificate',
  updateMerchantInfo: '/public/v1/merchants',
  goLive: 'public/v1/merchants/goLive',
  goLiveBusiness: 'public/v1/merchants/goLiveBusiness',

  // Credit Debit Notes
  getCreditDebitNotes: '/public/v1/note',
  createCreditDebitNote: '/public/v1/note',
  exportCreditDebitNote: '/public/v1/note/export/:format',

  // Users
  getRoles: '/public/v1/users/roles',
  getMerchantRoles: '/public/v1/merchants/:merchantId/users/roles',
  getUsers: '/public/v1/users',
  createUser: '/public/v1/users',
  editUser: 'public/v1/users/:userId',
  deleteUser: 'public/v1/users/:userId',
  getMerchantUsers: '/public/v1/merchants/:merchantId/users',
  createMerchantUser: '/public/v1/merchants/:merchantId/users',
  editMerchantUser: 'public/v1/merchants/:merchantId/users/:userId',
  deleteMerchantUser: 'public/v1/merchants/:merchantId/users/:userId',
  updateName: '/public/v1/merchants/:merchantId/name',
  updateMerchantInformation: '/public/v1/merchants/:merchantId/information',
  updateMerchantDescription: '/public/v1/merchants/:merchantId/description',
  getLegalPartnerByUser: '/public/v1/users/:userId/legal/partner',
  getUserScopes: '/public/v1/users/:userId/scopes',
  updateUserScopes: '/public/v1/users/:userId/scopes',

  // Credentials
  getCredentials: '/public/v1/integration/credentials',
  deleteCredential: '/public/v1/integration/credentials/:credentialId',
  createCredential: '/public/v1/integration/credentials',
  getSecret: '/public/v1/integration/secret',
  resendEmail: '/public/v1/users/:userId/resendEmail',
  getMerchantCategoryCode: '/public/v1/merchants/merchantCategoryCode',
  updateMerchantCategoryCode: '/public/v1/merchants/updateMerchantCategoryCodeId',

  // Backend Credentials
  getBackendCredentials: '/public/v1/merchants/authorization/credentials',
  deleteBackendCredential: '/public/v1/merchants/authorization/credentials/:credentialId',
  createBackendCredential: '/public/v1/merchants/authorization/credentials',

  // Sdk Credentials
  getSdkCredentials: '/public/v1/merchants/authorization/sdk',
  deleteSdkCredential: '/public/v1/merchants/authorization/sdk/:credentialId',
  createSdkCredential: '/public/v1/merchants/authorization/sdk',

  // Micromerchants
  requestWithdrawal: '/public/v1/micro/merchants/cashout/init',

  // Legal Partner
  getLegalPartner: '/public/v1/merchants/legal/partner',
  getMerchantLegalPartner: '/public/v1/merchants/:merchantId/legal/partner',
  updateMerchantLegalPartner: '/public/v1/merchants/:merchantId/legal/partner',

  // Payments
  getCollaborator: '/public/v1/merchant/collaborator/search',
  createCollaborator: '/public/v1/merchant/collaborator/create',
  editCollaborator: '/public/v1/merchant/collaborator/:collaboratorExternalId',
  uploadCollaborator: '/public/v1/merchant/collaborator/upload',
  updateDataCollaborator: '/public/v1/merchant/collaborator/updateData',
  processCollaborator: '/public/v1/merchant/collaborator/process',
  getPaymentPreview: '/public/v1/merchant/payroll/preview',
  paymentExecute: '/public/v1/merchant/payroll/execute',
  activateAll: '/public/v1/merchant/collaborator/updateStatus/true',
  deactivateAll: '/public/v1/merchant/collaborator/updateStatus/false',
  getPayrollAgroupedList: '/public/v1/merchant/payroll',
  getPayrollExtendedList: '/public/v1/merchant/payroll/payments',
  getPayrollReportFile: '/public/v1/merchant/payroll/payments/report/XLSX',
  getPayrollDetails: '/public/v1/merchant/payroll/:payrollId',
  exportPayrollDetail: '/public/v1/merchant/payroll/:payrollId/report/:format',
  exportCollaborators: '/public/v1/merchant/collaborator/report/export/:format',
  getCollaboratorDetail: '/public/v1/merchant/collaborator/:collaboratorExternalId',
  getBalance: '/public/v1/merchant/payroll/balance',
  payCollaborator: '/public/v1/merchant/payroll/payment',
  getCollaboratorPayrolls: '/public/v1/merchant/collaborator/:collaboratorExternalId/payments',
  deleteCollaborator: '/public/v1/merchant/collaborator/:collaboratorExternalId',
  downloadCollaboratorPaymentReceipt:
    '/public/v1/merchant/collaborator/:collaboratorExternalId/payment/:referenceId/receipt',
  downloadCollaboratorPaymentInvoice:
    '/public/v1/merchant/collaborator/:collaboratorExternalId/payment/:referenceId/invoice',
  getExchangeRate: '/public/v1/merchant/payroll/:collaboratorExternalId/payment/exchange',

  // Payments - Contracts
  uploadContract: 'public/v1/merchant/collaborator/:collaboratorExternalId/uploadFile',
  deleteContractFile:
    '/public/v1/merchant/collaborator/:collaboratorExternalId/deleteFile/:contractId',
  getAllContracts: '/public/v1/merchant/collaborator/:collaboratorExternalId/allFiles',
  getContract: '/public/v1/merchant/collaborator/:collaboratorExternalId/file/:contractId',

  // Customers
  getCustomers: '/public/v1/customer/search',
  createCustomer: '/public/v1/customer',
  editCustomer: '/public/v1/customer/:externalId',

  // Request Payment (transactions)
  getRequestPayments: '/public/v1/customer/payment/search',
  getRequestPayment: '/public/v1/customer/payment/:externalId',
  cancelPaymentRequest: '/public/v1/customer/:externalId/cancelCustomerPayment',

  // Send Money
  sendMoney: '/public/v1/merchant/money-sending',
  getCustomer: '/public/v1/customer/:externalId',
  getCustomerTransactions: '/public/v1/customer/:externalId/payment',
  createPayment: '/public/v1/customer/:externalId/payment',
  getPosList: '/public/v1/pos/search',
  getPosTransactions: '/public/v1/paymentCode/search',
  pos: '/public/v1/pos',
  editPos: '/public/v1/pos/:externalId',
  getPosDetail: '/public/v1/pos/:externalId',
  getPosQr: '/public/v1/paymentCode/:paymentCode',
  getPosDynamicQr: '/public/v1/paymentCode',
  getPosDynamicQrInfo: '/public/v1/paymentCode/transaction',
  getPosDetailTransactions: '/public/v1/paymentCode/pos/:externalId/search',
  getExchangeMoney: '/public/v1/paymentCode/exchange/converter',
  createPaymentLink: '/public/v1/customer/payment',

  // Members Contracts (Internal)
  validateCollaboratorContracts:
    '/public/v1/merchant/collaborator/:collaboratorExternalId/validateContracts',
  getCollaboratorsMerchant: '/public/v1/merchant/collaborator/searchCollaboratorContracts',
  getCollaboratorContract: '/public/v1/merchant/collaborator/file/:fileId',

  // Dashboard
  getPayrollCharts: '/public/v1/dashboard',
};

export default urls;
