import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { dateWeekByDefault } from '../../../config/constants/date-formats';
import { CreditDebitFilterState } from './types';

const initialState: CreditDebitFilterState = {
  creditDebitFilters: dateWeekByDefault,
  creditDebitDates: dateWeekByDefault,
  creditDebitMerchant: '',
  creditDebitId: '',
  creditDebitType: '',
};

export const creditDebitFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    creditDebitFilters: (state, action: PayloadAction<string>) => {
      state.creditDebitFilters = action.payload;
    },
    creditDebitDates: (state, action: PayloadAction<string>) => {
      state.creditDebitDates = action.payload;
    },
    creditDebitMerchant: (state, action: PayloadAction<string>) => {
      state.creditDebitMerchant = action.payload;
    },
    creditDebitId: (state, action: PayloadAction<string>) => {
      state.creditDebitId = action.payload;
    },
    creditDebitType: (state, action: PayloadAction<string>) => {
      state.creditDebitType = action.payload;
    },
    resetCreditDebitFilters: (state) => {
      state.creditDebitFilters = initialState.creditDebitFilters;
      state.creditDebitDates = initialState.creditDebitDates;
      state.creditDebitMerchant = initialState.creditDebitMerchant;
      state.creditDebitId = initialState.creditDebitId;
      state.creditDebitType = initialState.creditDebitType;
    },
  },
});

// Selectors
const getCreditDebitFilters = (state: RootState): CreditDebitFilterState =>
  state.creditDebitFilters;
export const getCreditDebitsFilters = createSelector(
  getCreditDebitFilters,
  (filter) => filter.creditDebitFilters,
);
export const getCreditDebitDates = createSelector(
  getCreditDebitFilters,
  (filter) => filter.creditDebitDates,
);
export const getCreditDebitMerchant = createSelector(
  getCreditDebitFilters,
  (filter) => filter.creditDebitMerchant,
);
export const getCreditDebitId = createSelector(
  getCreditDebitFilters,
  (filter) => filter.creditDebitId,
);
export const getCreditDebitType = createSelector(
  getCreditDebitFilters,
  (filter) => filter.creditDebitType,
);

const { actions, reducer } = creditDebitFilterSlice;

export const {
  creditDebitFilters,
  creditDebitDates,
  creditDebitMerchant,
  creditDebitId,
  creditDebitType,
  resetCreditDebitFilters,
} = actions;

export default reducer;
