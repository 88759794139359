import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SettlementFilterState } from './types';
import { dateWeekByDefault } from '../../../config/constants/date-formats';

const initialState: SettlementFilterState = {
  settlementFilters: dateWeekByDefault,
  settlementDates: dateWeekByDefault,
  settlementMerchant: '',
  settlementMerchantId: '',
  settlementStatus: '',
  settlementType: '',
};

export const settlementFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    settlementFilters: (state, action: PayloadAction<string>) => {
      state.settlementFilters = action.payload;
    },
    settlementDates: (state, action: PayloadAction<string>) => {
      state.settlementDates = action.payload;
    },
    settlementMerchant: (state, action: PayloadAction<string>) => {
      state.settlementMerchant = action.payload;
    },
    settlementMerchantId: (state, action: PayloadAction<string>) => {
      state.settlementMerchantId = action.payload;
    },
    settlementStatus: (state, action: PayloadAction<string>) => {
      state.settlementStatus = action.payload;
    },
    settlementType: (state, action: PayloadAction<string>) => {
      state.settlementType = action.payload;
    },
    resetSettlementFilters: (state) => {
      state.settlementFilters = initialState.settlementFilters;
      state.settlementDates = initialState.settlementDates;
    },
  },
});

// Selectors
const getSettlementFilters = (state: RootState): SettlementFilterState => state.settlementFilters;
export const getSettlementsFilters = createSelector(
  getSettlementFilters,
  (filter) => filter.settlementFilters,
);
export const getSettlementDates = createSelector(
  getSettlementFilters,
  (filter) => filter.settlementDates,
);
export const getSettlementMerchant = createSelector(
  getSettlementFilters,
  (filter) => filter.settlementMerchant,
);
export const getSettlementMerchantId = createSelector(
  getSettlementFilters,
  (filter) => filter.settlementMerchantId,
);
export const getSettlementStatus = createSelector(
  getSettlementFilters,
  (filter) => filter.settlementStatus,
);
export const getSettlementType = createSelector(
  getSettlementFilters,
  (filter) => filter.settlementType,
);

const { actions, reducer } = settlementFilterSlice;

export const {
  settlementFilters,
  settlementDates,
  settlementMerchant,
  settlementMerchantId,
  settlementStatus,
  settlementType,
  resetSettlementFilters,
} = actions;

export default reducer;
