import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getMerchantMenu = () => {
  const merchant: SubMenuItemType[] = [
    {
      path: RoutePaths.merchants,
      icon: 'address-book',
      title: 'merchants',
      roles: [role.internal],
      hasPermission: true,
      id: 'mp-main-merchant-action',
    },
  ];
  return merchant;
};

const getMerchantRoutes = () => {
  const MerchantRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.merchants,
      component: React.lazy(() => import('../../pages/merchant/merchant-page')),
      roles: [role.internal],
      hasPermission: true,
    },
    {
      path: RoutePaths.merchantDetail,
      component: React.lazy(() => import('../../pages/merchant/detail/merchant-details')),
      roles: [role.internal, role.merchant],
      hasPermission: true,
    },
  ];
  return MerchantRoutes;
};

export default getMerchantRoutes;
