import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { MerchantFilterState } from './types';

const initialState: MerchantFilterState = {
  merchantFilters: '',
  merchantId: '',
  merchantStatus: '',
  merchantLegal: '',
  merchantEmail: '',
  merchantOnlyActives: false,
};

export const merchantFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    merchantFilters: (state, action: PayloadAction<string>) => {
      state.merchantFilters = action.payload;
    },
    merchantId: (state, action: PayloadAction<string>) => {
      state.merchantId = action.payload;
    },
    merchantStatus: (state, action: PayloadAction<string>) => {
      state.merchantStatus = action.payload;
    },
    merchantLegal: (state, action: PayloadAction<string>) => {
      state.merchantLegal = action.payload;
    },
    merchantEmail: (state, action: PayloadAction<string>) => {
      state.merchantEmail = action.payload;
    },
    merchantOnlyActives: (state, action: PayloadAction<boolean>) => {
      state.merchantOnlyActives = action.payload;
    },
    resetMerchantFilters: (state) => {
      state.merchantFilters = initialState.merchantFilters;
      state.merchantId = initialState.merchantId;
      state.merchantStatus = initialState.merchantStatus;
      state.merchantLegal = initialState.merchantLegal;
      state.merchantEmail = initialState.merchantEmail;
      state.merchantOnlyActives = initialState.merchantOnlyActives;
    },
  },
});

// Selectors
const getMerchantFilters = (state: RootState): MerchantFilterState => state.merchantFilters;
export const getMerchantsFilters = createSelector(
  getMerchantFilters,
  (filter) => filter.merchantFilters,
);
export const getMerchantId = createSelector(getMerchantFilters, (filter) => filter.merchantId);
export const getMerchantStatus = createSelector(
  getMerchantFilters,
  (filter) => filter.merchantStatus,
);
export const getMerchantLegal = createSelector(
  getMerchantFilters,
  (filter) => filter.merchantLegal,
);
export const getMerchantEmail = createSelector(
  getMerchantFilters,
  (filter) => filter.merchantEmail,
);
export const getMerchantOnlyActives = createSelector(
  getMerchantFilters,
  (filter) => filter.merchantOnlyActives,
);

const { actions, reducer } = merchantFilterSlice;

export const {
  merchantFilters,
  merchantId,
  merchantLegal,
  merchantStatus,
  merchantEmail,
  merchantOnlyActives,
  resetMerchantFilters,
} = actions;

export default reducer;
