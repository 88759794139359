import { dateByDefault, dateWeekByDefault } from '../../../config/constants/date-formats';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FGCashoutFilterState } from './types';
import { createSelector } from 'reselect';
import { RootState } from '../../store';

const initialState: FGCashoutFilterState = {
  fgCashoutFilters: dateByDefault,
  fgCashoutDates: dateByDefault,
  fgCashoutId: '',
  fgCashoutReference: '',
  fgCashoutCountry: '',
  fgCashoutStatus: '',
  fgCashoutMerchant: '',
  fgCashoutOnlyActives: false,
  fgCashoutMerchantUserId: '',
};

export const fGCashoutFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    fgCashoutFilters: (state, action: PayloadAction<string>) => {
      state.fgCashoutFilters = action.payload;
    },
    fgCashoutDates: (state, action: PayloadAction<string>) => {
      state.fgCashoutDates = action.payload;
    },
    fgCashoutId: (state, action: PayloadAction<string>) => {
      state.fgCashoutId = action.payload;
    },
    fgCashoutReference: (state, action: PayloadAction<string>) => {
      state.fgCashoutReference = action.payload;
    },
    fgCashoutCountry: (state, action: PayloadAction<string>) => {
      state.fgCashoutCountry = action.payload;
    },
    fgCashoutStatus: (state, action: PayloadAction<string>) => {
      state.fgCashoutStatus = action.payload;
    },
    fgCashoutMerchant: (state, action: PayloadAction<string>) => {
      state.fgCashoutMerchant = action.payload;
    },
    fgCashoutOnlyActives: (state, action: PayloadAction<boolean>) => {
      state.fgCashoutOnlyActives = action.payload;
    },
    fgCashoutMerchantUserId: (state, action: PayloadAction<string>) => {
      state.fgCashoutMerchantUserId = action.payload;
    },
    resetFGCashoutFilters: (state) => {
      state.fgCashoutFilters = initialState.fgCashoutFilters;
      state.fgCashoutDates = initialState.fgCashoutDates;
      state.fgCashoutId = initialState.fgCashoutId;
      state.fgCashoutReference = initialState.fgCashoutReference;
      state.fgCashoutCountry = initialState.fgCashoutCountry;
      state.fgCashoutStatus = initialState.fgCashoutStatus;
      state.fgCashoutMerchant = initialState.fgCashoutMerchant;
      state.fgCashoutOnlyActives = initialState.fgCashoutOnlyActives;
      state.fgCashoutMerchantUserId = initialState.fgCashoutMerchantUserId;
    },
    initFGCashoutMerchantFilters: (state, action: PayloadAction<string>) => {
      state.fgCashoutMerchant = `&merchant=${action.payload}`;
      state.fgCashoutFilters = `${dateWeekByDefault}&merchant=${action.payload}`;
      state.fgCashoutDates = dateWeekByDefault;
    },
  },
});

// Selectors
const getFGCashoutFilters = (state: RootState): FGCashoutFilterState => state.fgCashoutFilters;
export const getFGCashoutsFilters = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutFilters,
);
export const getFGCashoutDates = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutDates,
);
export const getFGCashoutId = createSelector(getFGCashoutFilters, (filter) => filter.fgCashoutId);
export const getFGCashoutReference = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutReference,
);
export const getFGCashoutCountry = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutCountry,
);
export const getFGCashoutStatus = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutStatus,
);
export const getFGCashoutMerchant = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutMerchant,
);
export const getFGCashoutOnlyActives = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutOnlyActives,
);
export const getFGCashoutMerchantUserId = createSelector(
  getFGCashoutFilters,
  (filter) => filter.fgCashoutMerchantUserId,
);

const { actions, reducer } = fGCashoutFilterSlice;

export const {
  fgCashoutFilters,
  fgCashoutDates,
  fgCashoutId,
  fgCashoutReference,
  fgCashoutCountry,
  fgCashoutStatus,
  fgCashoutMerchant,
  fgCashoutOnlyActives,
  fgCashoutMerchantUserId,
  resetFGCashoutFilters,
  initFGCashoutMerchantFilters,
} = actions;

export default reducer;
