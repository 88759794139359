import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getMerchantAccountMenu = () => {
  const merchantAccount: SubMenuItemType[] = [
    {
      path: RoutePaths.merchantAccount,
      icon: 'cog',
      title: 'merchant_settings',
      roles: [role.merchant],
      hasPermission: true,
    },
  ];

  return merchantAccount;
};

const getMerchantAccountRoutes = () => {
  const MerchantAccountRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.merchantAccount,
      component: React.lazy(() => import('../../pages/merchant-account/merchant-account')),
      roles: [role.merchant],
      hasPermission: true,
    },
  ];
  return MerchantAccountRoutes;
};

export default getMerchantAccountRoutes;
