/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikErrors, FormikTouched } from 'formik';
import { camelCase, snakeCase } from './string';

export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const isObject = (value: unknown): boolean => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

export const getRandomItem = <T>(array: T[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

/**
 * Returns error message of validation
 * @param fieldName
 * @param errors
 * @param touched
 * @returns {string} Returns error message of validation
 */
export const errorMessageByField = <T>(
  fieldName: string,
  touched: any = {} as FormikTouched<T>,
  errors: any = {} as FormikErrors<T>,
) => {
  if (fieldName.indexOf('.') > -1) {
    const splittedName = fieldName.split('.');
    const touchedObject = touched[splittedName[0] as keyof T];
    const errorsObject = splittedName[0] in errors && errors[splittedName[0] as keyof T];
    if (errorsObject && touchedObject) {
      const errorMessage =
        (touched[splittedName[0]][splittedName[1]] as boolean) &&
        errors[splittedName[0]][splittedName[1]];
      return errorMessage;
    }
  } else
    return fieldName in touched && fieldName in errors && (errors[fieldName as keyof T] as string);
};

export const decimalAdjust = (value: number, decimal = 2) => {
  let positiveValue = value;
  if (value < 0) {
    positiveValue = value * -1;
  }
  const integerDigits = (v: number) => {
    let count = 1;
    while (Math.trunc(v) / Math.pow(10, count) >= 1) {
      count++;
    }
    return count;
  };
  const countInteger = integerDigits(positiveValue);

  const decimals =
    Math.floor((Math.pow(10, decimal) * positiveValue) % Math.pow(10, decimal)) /
    Math.pow(10, decimal);
  if (decimals > 0) {
    return parseFloat(Number(Math.trunc(value) + decimals).toPrecision(countInteger + decimal));
  }
  return parseFloat(Number(value).toPrecision(countInteger + decimal));
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const toCamelCaseObject = <T1 extends {}, T2 = {}>(
  obj: T2,
  indexs: { [key: string]: keyof T1 } = {},
): T1 =>
  Object.entries(obj).reduce(
    (u, [attr, value]) => ({
      ...u,
      [attr in indexs ? indexs[attr] : camelCase(attr)]:
        isObject(value) && !Array.isArray(value)
          ? toCamelCaseObject<any>(value, indexs)
          : Array.isArray(value)
          ? value.map((v) => (isObject(v) ? toCamelCaseObject(v, indexs) : v))
          : value,
    }),
    {} as T1,
  );

// eslint-disable-next-line @typescript-eslint/ban-types
export const toSnakeCaseObject = <T1 extends {}, T2 = {}>(
  obj: T2,
  indexs: { [key: string]: keyof T1 } = {},
): T1 =>
  Object.entries(obj).reduce(
    (u, [attr, value]) => ({
      ...u,
      [attr in indexs ? indexs[attr] : snakeCase(attr)]:
        isObject(value) && !Array.isArray(value)
          ? toSnakeCaseObject<any>(value, indexs)
          : Array.isArray(value)
          ? value.map((v) => (isObject(v) ? toSnakeCaseObject(v, indexs) : v))
          : value,
    }),
    {} as T1,
  );

export const extractFromObj = <T>(obj: T, keys: (keyof T)[], autocomplete = true) =>
  keys.reduce(
    (newObj, key) =>
      key in obj
        ? { ...newObj, [key]: obj[key] }
        : autocomplete
        ? { ...newObj, [key]: '' }
        : newObj,
    {} as Partial<T>,
  );

// eslint-disable-next-line @typescript-eslint/ban-types
export const searchParamsToObject = <T extends object>(param: string, emptyObject: T) => {
  const url = new URLSearchParams(param);
  let entriesParams = Object.fromEntries(url) as T;
  const emptyObj = emptyObject;
  Object.keys(emptyObj).forEach((key) => {
    const typeKeyEmptyObj = typeof emptyObj[key as keyof T];
    if (key in entriesParams) {
      const typeOfParam = typeof entriesParams[key as keyof T];
      if (typeof typeOfParam !== typeKeyEmptyObj) {
        const value = entriesParams[key as keyof T];
        if (typeKeyEmptyObj === 'boolean') {
          entriesParams = {
            ...entriesParams,
            [key]: String(value) === 'true',
          };
        }
        if (typeKeyEmptyObj === 'object') {
          // Expected a 'string,string' to string[]
          entriesParams = {
            ...entriesParams,
            [key]: String(value).split(','),
          };
        }
        if (typeKeyEmptyObj === 'number') {
          entriesParams = {
            ...entriesParams,
            [key]: Number(value),
          };
        }
      }
    }
  });
  return entriesParams;
};

export const downloadFile = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url || '';
  link.target = '_blank';
  if (fileName) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
