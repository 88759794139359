import { Middleware } from 'redux';
import { requestFailed } from './global';
import { RequestError } from './global/types';

export const crashReport: Middleware = () => (next) => (action) => {
  const { payload } = action;
  if (payload && payload.hasError) {
    return next(requestFailed(payload as RequestError));
  }
  return next(action);
};
