import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FGDepositFilterState } from './types';
import { dateByDefault, dateWeekByDefault } from '../../../config/constants/date-formats';

const initialState: FGDepositFilterState = {
  fgDepositFilters: dateByDefault,
  fgDepositDates: dateByDefault,
  fgDepositId: '',
  fgDepositReference: '',
  fgDepositCountry: '',
  fgDepositStatus: '',
  fgDepositMerchant: '',
  fgDepositOnlyActives: false,
  fgDepositMerchantUserId: '',
};

export const fgDepositFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    fgDepositFilters: (state, action: PayloadAction<string>) => {
      state.fgDepositFilters = action.payload;
    },
    fgDepositDates: (state, action: PayloadAction<string>) => {
      state.fgDepositDates = action.payload;
    },
    fgDepositId: (state, action: PayloadAction<string>) => {
      state.fgDepositId = action.payload;
    },
    fgDepositReference: (state, action: PayloadAction<string>) => {
      state.fgDepositReference = action.payload;
    },
    fgDepositCountry: (state, action: PayloadAction<string>) => {
      state.fgDepositCountry = action.payload;
    },
    fgDepositStatus: (state, action: PayloadAction<string>) => {
      state.fgDepositStatus = action.payload;
    },
    fgDepositMerchant: (state, action: PayloadAction<string>) => {
      state.fgDepositMerchant = action.payload;
    },
    fgDepositOnlyActives: (state, action: PayloadAction<boolean>) => {
      state.fgDepositOnlyActives = action.payload;
    },
    fgDepositMerchantUserId: (state, action: PayloadAction<string>) => {
      state.fgDepositMerchantUserId = action.payload;
    },
    resetFGDepositFilters: (state) => {
      state.fgDepositFilters = initialState.fgDepositFilters;
      state.fgDepositDates = initialState.fgDepositDates;
      state.fgDepositId = initialState.fgDepositId;
      state.fgDepositReference = initialState.fgDepositReference;
      state.fgDepositCountry = initialState.fgDepositCountry;
      state.fgDepositStatus = initialState.fgDepositStatus;
      state.fgDepositMerchant = initialState.fgDepositMerchant;
      state.fgDepositOnlyActives = initialState.fgDepositOnlyActives;
      state.fgDepositMerchantUserId = initialState.fgDepositMerchant;
    },
    initFGDepositMerchantFilters: (state, action: PayloadAction<string>) => {
      state.fgDepositMerchant = `&merchant=${action.payload}`;
      state.fgDepositFilters = `${dateWeekByDefault}&merchant=${action.payload}`;
      state.fgDepositDates = dateWeekByDefault;
    },
  },
});

// Selectors
const getFGDepositFilters = (state: RootState): FGDepositFilterState => state.fgDepositFilters;
export const getFGDepositsFilters = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositFilters,
);
export const getFGDepositDates = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositDates,
);
export const getFGDepositId = createSelector(getFGDepositFilters, (filter) => filter.fgDepositId);
export const getFGDepositReference = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositReference,
);
export const getFGDepositCountry = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositCountry,
);
export const getFGDepositStatus = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositStatus,
);
export const getFGDepositMerchant = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositMerchant,
);
export const getFGDepositOnlyActives = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositOnlyActives,
);
export const getFGDepositMerchantUserId = createSelector(
  getFGDepositFilters,
  (filter) => filter.fgDepositMerchantUserId,
);

const { actions, reducer } = fgDepositFilterSlice;

export const {
  fgDepositFilters,
  fgDepositDates,
  fgDepositId,
  fgDepositReference,
  fgDepositCountry,
  fgDepositStatus,
  fgDepositMerchant,
  fgDepositOnlyActives,
  fgDepositMerchantUserId,
  resetFGDepositFilters,
  initFGDepositMerchantFilters,
} = actions;

export default reducer;
