import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getCredentialsMenu = (userScopes: string[]) => {
  const credentialsMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.credentials,
      icon: 'key',
      title: 'credentials',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.credentials),
      subTabItems: [
        {
          title: 'Api Key',
          icon: 'code',
          path: RoutePaths.apiKey,
          component: React.lazy(() => import('../../pages/credentials/credentials')),
          roles: [role.merchant],
          hasPermission: hasPermission(userScopes, scopes.credentials),
        },
        {
          title: 'Backend Api Key',
          icon: 'code',
          path: RoutePaths.backendApiKey,
          component: React.lazy(() => import('../../pages/credentials/backend-credentials')),
          roles: [role.merchant],
          hasPermission: hasPermission(userScopes, scopes.credentials),
        },
        {
          title: 'SDK Api Key',
          icon: 'code',
          path: RoutePaths.sdkApiKey,
          component: React.lazy(() => import('../../pages/credentials/sdk-credentials')),
          roles: [role.merchant],
          hasPermission: hasPermission(userScopes, scopes.credentials),
        },
      ],
    },
  ];

  return credentialsMenu;
};

const getCredentialsRoutes = (userScopes: string[]) => {
  const CredentialsRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.credentials,
      component: React.lazy(() => import('../../pages/credentials/credentials-page')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.credentials),
    },
    {
      path: RoutePaths.apiKey,
      component: React.lazy(() => import('../../pages/credentials/credentials')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.credentials),
    },
    {
      path: RoutePaths.backendApiKey,
      component: React.lazy(() => import('../../pages/credentials/backend-credentials-page')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.credentials),
    },
    {
      path: RoutePaths.sdkApiKey,
      component: React.lazy(() => import('../../pages/credentials/sdk-credentials-page')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.credentials),
    },
  ];

  return CredentialsRoutes;
};

export default getCredentialsRoutes;
