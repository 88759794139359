import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { CircularProgress, CssBaseline } from '@material-ui/core';
import './styles/index.css';
import { IconService } from './services';
import { trackEvents } from './config/events';
import store, { persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { PanelThemeProvider } from './PanelThemeProvider';
import AmplitudeTracker from './services/trackers/amplitude';

IconService.init();
AmplitudeTracker.init(trackEvents.amplitude);

const AuthRoot = React.lazy(() =>
  Promise.all([import('./auth-root'), new Promise((resolve) => setTimeout(resolve, 1000))]).then(
    ([appRootModule]) => appRootModule,
  ),
);

const root = document.getElementById('root');

export const LoadingBox = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress color="primary" />
  </div>
);

if (root) {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={LoadingBox()}>
        <Provider store={store}>
          <PanelThemeProvider>
            <PersistGate loading={<CircularProgress color="primary" />} persistor={persistor}>
              <CssBaseline />
              <AuthRoot />
            </PersistGate>
          </PanelThemeProvider>
        </Provider>
      </Suspense>
    </React.StrictMode>,
    root,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
