import Api from './http-service';
import urls from '../config/constants/urls';
import { toCamelCaseObject } from '../helpers/utils';
import { User } from '../redux/session/types';

export interface LoginRequest {
  username: string;
  password: string;
}

interface AuthResponse {
  validity: number;
  email: string;
  name: string;
  country: string;
  mfa_enabled: boolean;
  image?: string;
}

export interface ChangePasswordRequest {
  email: string;
  code: string;
  password: string;
}

export interface ActivateMFAResponse {
  mfa_url?: string;
  secret?: string;
}

let sessionUser: LoginRequest = {
  username: '',
  password: '',
};

export default class AuthService {
  static astroLogin = async (accessToken: string) => {
    try {
      const response = await Api.post<AuthResponse>(urls.astroLogin, {
        encoded_token: accessToken,
      });
      return toCamelCaseObject<User>(response);
    } catch (e) {
      throw e;
    }
  };
  static login = async (request: LoginRequest) => {
    sessionUser = request;
    const user = { email: request.username, password: request.password };
    const body = user;

    try {
      const response = await Api.post<AuthResponse>(urls.login, body);
      return toCamelCaseObject<User>(response);
    } catch (e) {
      throw e;
    }
  };

  static authenticateMFA = async (code: string) => {
    const request = {
      email: sessionUser.username,
      password: sessionUser.password,
      mfa_code: code,
    };
    const body = request;
    try {
      const response = await Api.post<AuthResponse>(urls.authMfa, body);
      return toCamelCaseObject<User>(response);
    } catch (e) {
      throw e;
    }
  };

  static activateMFA = async () => {
    try {
      const response = await Api.post<ActivateMFAResponse>(urls.createMfa);
      return response.mfa_url ?? response.secret ?? '';
    } catch (e) {
      throw e;
    }
  };

  static deactivateMFA = async () => {
    try {
      const response = await Api.post<string>(urls.deactivateMfa);
      return response;
    } catch (e) {
      throw e;
    }
  };

  static activateMfa = async (code: string) => {
    try {
      const response = await Api.post<string>(urls.activateMfa, { mfa_code: code });
      return response;
    } catch (e) {
      throw e;
    }
  };

  static forgotPassword = async (email: string) => {
    const body = { email: email };
    return await Api.post<string>(urls.forgotPass, body);
  };

  static recoveryPassword = async (request: ChangePasswordRequest) => {
    return await Api.post<string>(urls.recoveryPassword, {
      email: request.email,
      new_password: request.password,
      auth_code: request.code,
    });
  };

  static logout = async () => {
    return await Api.post<string>(urls.logout);
  };

  static internalLogin = async (code: string, codeVerifier: string) => {
    try {
      const response = await Api.post<string>(urls.internalLogin, {
        code: code,
        code_verifier: codeVerifier,
      });
      return toCamelCaseObject<User>(response);
    } catch (e) {
      throw e;
    }
  };
}
