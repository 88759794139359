import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { PaymentMethodFilterState } from './types';

const initialState: PaymentMethodFilterState = {
  paymentMethodFilters: '',
  country: '',
  payByAstropay: '',
};

export const paymentMethodFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    paymentMethodFilters: (state, action: PayloadAction<string>) => {
      state.paymentMethodFilters = action.payload;
    },
    paymentMethodCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    paymentMethodPayByAstropay: (state, action: PayloadAction<string>) => {
      state.payByAstropay = action.payload;
    },
    resetPaymentMethodFilters: (state) => {
      state.paymentMethodFilters = initialState.paymentMethodFilters;
      state.country = initialState.country;
      state.payByAstropay = initialState.payByAstropay;
    },
  },
});

// Selectors
const getPaymentMethodFilters = (state: RootState): PaymentMethodFilterState =>
  state.paymentMethodFilters;
export const getPaymentsMethodFilters = createSelector(
  getPaymentMethodFilters,
  (filter) => filter.paymentMethodFilters,
);
export const getCountry = createSelector(getPaymentMethodFilters, (filter) => filter.country);
export const getPayByAstropay = createSelector(
  getPaymentMethodFilters,
  (filter) => filter.payByAstropay,
);

const { actions, reducer } = paymentMethodFilterSlice;

export const {
  paymentMethodFilters,
  paymentMethodCountry,
  paymentMethodPayByAstropay,
  resetPaymentMethodFilters,
} = actions;

export default reducer;
