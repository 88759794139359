import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getUsersMenu = (userScopes: string[]) => {
  const users: SubMenuItemType[] = [
    {
      path: RoutePaths.users,
      icon: 'users',
      title: 'users',
      roles: [role.merchant, role.internal],
      hasPermission: hasPermission(userScopes, scopes.manageUsers),
    },
  ];

  return users;
};

const getUsersRoutes = (userScopes: string[]) => {
  const UsersRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.users,
      component: React.lazy(() => import('../../pages/users/users-page')),
      roles: [role.merchant, role.internal],
      hasPermission: hasPermission(userScopes, scopes.manageUsers),
    },
  ];

  return UsersRoutes;
};

export default getUsersRoutes;
