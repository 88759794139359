import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getPaymentMethodMenu = (userScopes: string[]) => {
  const PaymentMethod: SubMenuItemType[] = [
    {
      path: RoutePaths.paymentMethod,
      icon: 'tasks-alt',
      title: 'payment_methods',
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.paymentMethods),
    },
  ];

  return PaymentMethod;
};

const getPaymentMethodRoutes = (userScopes: string[]) => {
  const PaymentMethodRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.paymentMethod,
      component: React.lazy(() => import('../../pages/payment-method/payment-method-list')),
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.paymentMethods),
    },
  ];

  return PaymentMethodRoutes;
};

export default getPaymentMethodRoutes;
