import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { CollaboratorFilterState } from './types';
import { createSelector } from 'reselect';

const initialState: CollaboratorFilterState = {
  collaboratorFilters: '',
  collaboratorFirstName: '',
  collaboratorLastName: '',
  collaboratorPhone: '',
  collaboratorStatus: '',
  collaboratorWalletStatus: '',
  collaboratorCountry: '',
  collaboratorMemberId: '',
};

export const collaboratorFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    collaboratorFilters: (state, action: PayloadAction<string>) => {
      state.collaboratorFilters = action.payload;
    },
    collaboratorFirstName: (state, action: PayloadAction<string>) => {
      state.collaboratorFirstName = action.payload;
    },
    collaboratorLastName: (state, action: PayloadAction<string>) => {
      state.collaboratorLastName = action.payload;
    },
    collaboratorPhone: (state, action: PayloadAction<string>) => {
      state.collaboratorPhone = action.payload;
    },
    collaboratorStatus: (state, action: PayloadAction<string>) => {
      state.collaboratorStatus = action.payload;
    },
    collaboratorWalletStatus: (state, action: PayloadAction<string>) => {
      state.collaboratorWalletStatus = action.payload;
    },
    collaboratorCountry: (state, action: PayloadAction<string>) => {
      state.collaboratorCountry = action.payload;
    },
    collaboratorMemberId: (state, action: PayloadAction<string>) => {
      state.collaboratorMemberId = action.payload;
    },
    resetCollaboratorFilters: (state) => {
      state.collaboratorFilters = initialState.collaboratorFilters;
      state.collaboratorFirstName = initialState.collaboratorFirstName;
      state.collaboratorLastName = initialState.collaboratorLastName;
      state.collaboratorPhone = initialState.collaboratorPhone;
      state.collaboratorStatus = initialState.collaboratorStatus;
      state.collaboratorWalletStatus = initialState.collaboratorWalletStatus;
      state.collaboratorCountry = initialState.collaboratorCountry;
      state.collaboratorMemberId = initialState.collaboratorMemberId;
    },
  },
});

// Collaborator Selectors
const getCollaboratorFilters = (state: RootState): CollaboratorFilterState =>
  state.collaboratorFilters;

export const getCollaboratorsFilter = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorFilters,
);

export const getCollaboratorFirstName = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorFirstName,
);

export const getCollaboratorLastName = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorLastName,
);

export const getCollaboratorPhone = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorPhone,
);

export const getCollaboratorStatus = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorStatus,
);

export const getCollaboratorWalletStatus = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorWalletStatus,
);

export const getCollaboratorCountry = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorCountry,
);

export const getCollaboratorMemberId = createSelector(
  getCollaboratorFilters,
  (filter) => filter.collaboratorMemberId,
);

const { actions, reducer } = collaboratorFilterSlice;

export const {
  collaboratorFilters,
  collaboratorFirstName,
  collaboratorLastName,
  collaboratorPhone,
  collaboratorStatus,
  collaboratorWalletStatus,
  resetCollaboratorFilters,
  collaboratorCountry,
  collaboratorMemberId,
} = actions;

export default reducer;
