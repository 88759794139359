import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { crashReport } from './middlewares';
import depositFilterReducer from './filters/depositFilters';
import fgDepositFilterReducer from './filters/fgDepositFilters';
import fgCashoutFilterReducer from './filters/fgCashoutFilters';
import cashoutFilterReducer from './filters/cashoutFilters';
import merchantFilterReducer from './filters/merchantFilters';
import balanceFilterReducer from './filters/balanceFilters';
import settlementFilterReducer from './filters/settlementFilters';
import creditDebitFilterReducer from './filters/creditDebitFilters';
import paymentMethodFilterReducer from './filters/paymentMethodFilters';
import merchantDepositFilterReducer from './filters/merchantDepositFilters';
import payrollFilterReducer from './filters/payrollFilters';
import payrollExtendedFilterReducer from './filters/payrollExtendedFilters';
import collaboratorFilterReducer from './filters/collaboratorFilters';
import customerFilterReducer from './filters/customerFilters';
import posFilterReducer from './filters/posFilters';
import totalReducer from './total';
import globalReducer from './global';
import sessionReducer from './session';
import filtersReducer from './filters';

const filtersPersistConfig = {
  key: '__MERCHANT_PANEL__FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const depositFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__DEPOSIT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const fgDepositFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__FG_DEPOSIT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const fgCashoutFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__FG_CASHOUT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const cashoutFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__CASHOUT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const merchantFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__MERCHANT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const balanceFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__BALANCE_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const settlementFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__SETTLEMENT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const paymentMethodFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__PAYMENT_METHOD_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const merchantDepositFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__DEPOSIT_PAYMENT_METHOD_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const creditDebitFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__CREDIT_DEBIT_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const totalsPersistConfig = {
  key: '__MERCHANT_PANEL__TOTALS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const globalPersistConfig = {
  key: '__MERCHANT_PANEL__GLOBAL__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const sessionPersistConfig = {
  key: '__MERCHANT_PANEL__SESSION__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const payrollFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__PAYROLL_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const payrollExtendedFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__PAYROLL_EXTENDED_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const collaboratorFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__COLLABORATOR_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const customerFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__CUSTOMER_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const posFiltersPersistConfig = {
  key: '__MERCHANT_PANEL__POS_FILTERS__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const store = configureStore({
  reducer: {
    filters: persistReducer(filtersPersistConfig, filtersReducer),
    depositFilters: persistReducer(depositFiltersPersistConfig, depositFilterReducer),
    fgDepositFilters: persistReducer(fgDepositFiltersPersistConfig, fgDepositFilterReducer),
    cashoutFilters: persistReducer(cashoutFiltersPersistConfig, cashoutFilterReducer),
    fgCashoutFilters: persistReducer(fgCashoutFiltersPersistConfig, fgCashoutFilterReducer),
    merchantFilters: persistReducer(merchantFiltersPersistConfig, merchantFilterReducer),
    balanceFilters: persistReducer(balanceFiltersPersistConfig, balanceFilterReducer),
    settlementFilters: persistReducer(settlementFiltersPersistConfig, settlementFilterReducer),
    paymentMethodFilters: persistReducer(
      paymentMethodFiltersPersistConfig,
      paymentMethodFilterReducer,
    ),
    merchantDepositFilterReducer: persistReducer(
      merchantDepositFiltersPersistConfig,
      merchantDepositFilterReducer,
    ),
    payrollFilters: persistReducer(payrollFiltersPersistConfig, payrollFilterReducer),
    payrollExtendedFilters: persistReducer(
      payrollExtendedFiltersPersistConfig,
      payrollExtendedFilterReducer,
    ),
    creditDebitFilters: persistReducer(creditDebitFiltersPersistConfig, creditDebitFilterReducer),
    collaboratorFilters: persistReducer(
      collaboratorFiltersPersistConfig,
      collaboratorFilterReducer,
    ),
    customerFilters: persistReducer(customerFiltersPersistConfig, customerFilterReducer),
    posFilters: persistReducer(posFiltersPersistConfig, posFilterReducer),
    total: persistReducer(totalsPersistConfig, totalReducer),
    global: persistReducer(globalPersistConfig, globalReducer),
    session: persistReducer(sessionPersistConfig, sessionReducer),
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).prepend(crashReport),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// export store singleton instance
export default store;
