import { DashboardStepsInfo, GlobalState, RequestError } from './types';
import { ColumnFilter } from '../../components/filters/column-filters';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

const defaultCountry = 'BR';

export const defaultDashboardStepsInfo = {
  activeStep: 0,
  url: '',
  industry: '',
  transactionMonthly: '',
  legalName: '',
  commercialName: '',
  contactName: '',
  contactLastName: '',
  termsAndConditions: false,
};

const initialState: GlobalState = {
  error: undefined,
  countryByIp: defaultCountry,
  merchantCurrency: '',
  cashoutColumnFilters: null,
  depositColumnFilters: null,
  fgDepositColumnFilters: null,
  fgCashoutColumnFilters: null,
  balanceColumnFilters: null,
  merchantColumnFilters: null,
  settlementColumnFilters: null,
  paymentMethodColumnFilters: null,
  creditDebitColumnFilters: null,
  payrollColumnFilters: null,
  collaboratorColumnFilters: null,
  dashboardStepsInfo: defaultDashboardStepsInfo,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setCountryByIP: (state, action: PayloadAction<string>) => {
      state.countryByIp = action.payload;
    },
    setMerchantCurrency: (state, action: PayloadAction<string>) => {
      state.merchantCurrency = action.payload;
    },
    requestFailed: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload;
    },
    cashoutColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.cashoutColumnFilters = action.payload;
    },
    depositColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.depositColumnFilters = action.payload;
    },
    fgDepositColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.depositColumnFilters = action.payload;
    },
    fgCashoutColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.cashoutColumnFilters = action.payload;
    },
    balanceColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.balanceColumnFilters = action.payload;
    },
    merchantColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.merchantColumnFilters = action.payload;
    },
    settlementColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.settlementColumnFilters = action.payload;
    },
    paymentMethodColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.paymentMethodColumnFilters = action.payload;
    },
    creditDebitColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.creditDebitColumnFilters = action.payload;
    },
    setDashboardStepsInfo: (state, action: PayloadAction<DashboardStepsInfo>) => {
      state.dashboardStepsInfo = action.payload;
    },
    payrollColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.payrollColumnFilters = action.payload;
    },
    collaboratorColumnFilters: (state, action: PayloadAction<ColumnFilter[]>) => {
      state.collaboratorColumnFilters = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
});

// Selectors
const getGlobal = (state: RootState): GlobalState => state.global;
export const getError = createSelector(getGlobal, (global) => global.error);
export const getCountryByIp = createSelector(getGlobal, (global) => global.countryByIp);
export const getMerchantCurrency = createSelector(getGlobal, (global) => global.merchantCurrency);
export const getDashboardStepsInfo = createSelector(
  getGlobal,
  (global) => global.dashboardStepsInfo,
);
export const getCashoutColumnFilters = createSelector(
  getGlobal,
  (global) => global.cashoutColumnFilters,
);
export const getDepositColumnFilters = createSelector(
  getGlobal,
  (global) => global.depositColumnFilters,
);
export const getFGDepositColumnFilters = createSelector(
  getGlobal,
  (global) => global.fgDepositColumnFilters,
);
export const getFGCashoutColumnFilters = createSelector(
  getGlobal,
  (global) => global.fgCashoutColumnFilters,
);
export const getBalanceColumnFilters = createSelector(
  getGlobal,
  (global) => global.balanceColumnFilters,
);
export const getMerchantColumnFilters = createSelector(
  getGlobal,
  (global) => global.merchantColumnFilters,
);
export const getSettlementColumnFilters = createSelector(
  getGlobal,
  (global) => global.settlementColumnFilters,
);
export const getPaymentMethodColumnFilters = createSelector(
  getGlobal,
  (global) => global.paymentMethodColumnFilters,
);
export const getCreditDebitColumnFilters = createSelector(
  getGlobal,
  (global) => global.creditDebitColumnFilters,
);
export const getPayrollColumnFilters = createSelector(
  getGlobal,
  (global) => global.payrollColumnFilters,
);
export const getCollaboratorColumnFilters = createSelector(
  getGlobal,
  (global) => global.collaboratorColumnFilters,
);

const { actions, reducer } = globalSlice;

export const {
  setCountryByIP,
  setMerchantCurrency,
  setDashboardStepsInfo,
  requestFailed,
  cashoutColumnFilters,
  depositColumnFilters,
  fgDepositColumnFilters,
  fgCashoutColumnFilters,
  balanceColumnFilters,
  merchantColumnFilters,
  settlementColumnFilters,
  paymentMethodColumnFilters,
  creditDebitColumnFilters,
  clearError,
  payrollColumnFilters,
  collaboratorColumnFilters,
} = actions;

export default reducer;
