import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { PosFilterState } from './types';
import { payrollDateByDefault } from '../../../config/constants/date-formats';
import { createSelector } from 'reselect';

const initialState: PosFilterState = {
  posFilters: payrollDateByDefault,
  posName: '',
  status: '',
};

export const posFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    posFilters: (state, action: PayloadAction<string>) => {
      state.posFilters = action.payload;
    },
    posName: (state, action: PayloadAction<string>) => {
      state.posName = action.payload;
    },
    posStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    resetPosFilters: (state) => {
      state.posFilters = initialState.posFilters;
      state.status = initialState.status;
      state.posName = initialState.posName;
    },
  },
});

// Pos Selectors
const getPosFilters = (state: RootState): PosFilterState => state.posFilters;

export const getPossFilters = createSelector(getPosFilters, (filter) => filter.posFilters);
export const getPosStatus = createSelector(getPosFilters, (filter) => filter.status);
export const getPosName = createSelector(getPosFilters, (filter) => filter.posName);
const { actions, reducer } = posFilterSlice;

export const { posFilters, posName, posStatus, resetPosFilters } = actions;

export default reducer;
