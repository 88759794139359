import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getCollaboratorsMenu = (userScopes: string[]) => {
  const collaboratorsMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.members,
      icon: 'users',
      title: 'members',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
      id: 'mp-payouts-members-action',
    },
  ];

  return collaboratorsMenu;
};

const getCollaboratorRoutes = (userScopes: string[]) => {
  const CollaboratorsRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.members,
      component: React.lazy(() => import('../../pages/collaborator/collaborators')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
    },
    {
      path: RoutePaths.memberDetail,
      component: React.lazy(
        () => import('../../pages/collaborator/collaborator-detail/collaborator-detail'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payroll),
    },
  ];

  return CollaboratorsRoutes;
};

export default getCollaboratorRoutes;
