export interface User {
  email: string;
  merchantId: string | number;
  merchantName: string;
  merchantLive: boolean;
  multipleMerchants: boolean;
  mfaEnabled: boolean;
  image?: string;
  name: string;
  role: string;
  roleLevel: number;
  scopes: string[];
  validity: number;
}

export interface SessionState {
  user: User;
  authenticated: boolean;
}

export const emptyUser: User = {
  email: '',
  merchantId: '',
  merchantName: '',
  merchantLive: false,
  multipleMerchants: false,
  mfaEnabled: false,
  image: '',
  name: '',
  role: '',
  roleLevel: -1,
  scopes: ['transactions_reports'],
  validity: 0,
};
