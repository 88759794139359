import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { PayrollExtendedFilterState } from './types';
import { payrollDateByDefault } from '../../../config/constants/date-formats';
import { createSelector } from 'reselect';

const initialState: PayrollExtendedFilterState = {
  payrollFilters: payrollDateByDefault,
  payrollDates: payrollDateByDefault,
  country: '',
  status: '',
};

export const payrollFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    payrollFilters: (state, action: PayloadAction<string>) => {
      state.payrollFilters = action.payload;
    },
    payrollDates: (state, action: PayloadAction<string>) => {
      state.payrollDates = action.payload;
    },
    payrollStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    payrollCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    resetPayrollFilters: (state) => {
      state.payrollFilters = initialState.payrollFilters;
      state.payrollDates = initialState.payrollDates;
      state.status = initialState.status;
      state.country = initialState.country;
    },
  },
});

// Payroll Selectors
const getPayrollFilters = (state: RootState): PayrollExtendedFilterState =>
  state.payrollExtendedFilters;

export const getPayrollsFilters = createSelector(
  getPayrollFilters,
  (filter) => filter.payrollFilters,
);
export const getPayrollDates = createSelector(getPayrollFilters, (filter) => filter.payrollDates);
export const getPayrollStatus = createSelector(getPayrollFilters, (filter) => filter.status);
export const getPayrollCountry = createSelector(getPayrollFilters, (filter) => filter.country);
const { actions, reducer } = payrollFilterSlice;

export const {
  payrollFilters,
  payrollDates,
  resetPayrollFilters,
  payrollCountry,
  payrollStatus,
} = actions;

export default reducer;
