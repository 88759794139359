import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { role } from '../../config/constants/roles';
import { SubMenuItemType } from '../../config/constants/menu.types';

export const getCreditDebitNotesMenu = (userScopes: string[]) => {
  const creditDebitNotes: SubMenuItemType[] = [
    {
      path: RoutePaths.creditDebitNotes,
      icon: 'file-invoice-dollar',
      title: 'credit_debit_notes',
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.creditDebitNote),
      id: 'mp-main-credit-debit-notes-action',
    },
  ];

  return creditDebitNotes;
};

const getCreditDebitNotesRoutes = (userScopes: string[]) => {
  const CreditDebitNotesRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.creditDebitNotes,
      component: React.lazy(() => import('../../pages/credit-debit-notes/credit-debit-notes')),
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.creditDebitNote),
    },
  ];

  return CreditDebitNotesRoutes;
};

export default getCreditDebitNotesRoutes;
