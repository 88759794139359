import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

export const getPaymentLinkMenu = (userScopes: string[]) => {
  const paymentLinkMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.paymentLink,
      icon: 'arrow-right',
      fullIcon: faLink,
      title: 'payment_link',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
  ];

  return paymentLinkMenu;
};

const getPaymentLinkRoutes = (userScopes: string[]) => {
  const PaymentLinkRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.paymentLink,
      component: React.lazy(() => import('../../pages/customers/payment-link')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.payins),
    },
  ];

  return PaymentLinkRoutes;
};

export default getPaymentLinkRoutes;
