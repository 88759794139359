const RoutePaths = {
  home: '/',
  dashboard: '/dashboard',
  login: '/auth/:step',
  internalLogin: '/internalLogin',
  signUp: '/signUp',
  signUpBusiness: '/signUp/business',
  signUpV1: '/signup/sign_up',
  confirmEmail: '/confirmEmail',
  confirmEmailBusiness: '/business/confirmEmail',
  setPassword: '/setPassword',
  forgotPassword: '/recovery/password/:step',
  oneTouch: '/onetouch',
  depositList: '/onetouch/deposits',
  depositDetail: '/onetouch/deposits/:depositId',
  cashoutList: '/onetouch/cashouts',
  cashoutDetail: '/onetouch/cashouts/:cashoutId',
  merchants: '/merchants',
  selectMerchant: '/selectmerchant',
  merchantDetail: '/merchants/:merchantId',
  balanceReport: '/balancereport',
  settlements: '/settlements',
  createSettlement: '/createsettlement',
  settlementDetail: '/settlements/:id',
  paymentMethod: '/paymentMethod',
  users: '/users',
  credentials: '/credentials',
  apiKey: '/apiKey',
  backendApiKey: '/backendApiKey',
  sdkApiKey: '/sdkApiKey',
  creditDebitNotes: '/creditDebitNotes',
  merchantAccount: '/merchantAccount',
  newPassword: '/newPassword',
  uploadCashoutFile: '/upload-cashout-file',
  fullGatewayDeposits: '/fullgateway-deposits',
  fullGatewayDepositsDetails: '/fullgateway-deposits-details/:depositId',
  fullGatewayCashouts: '/fullgateway-cashouts',
  fullGatewayCashoutsDetails: '/fullgateway-cashouts-details/:cashoutId',
  payments: '/payments',
  members: '/members',
  memberDetail: '/members/:collaboratorExternalId',
  payroll: '/payroll',
  payrollDetail: '/payroll/:payrollExternalId',
  customers: '/customers',
  sendMoney: '/send-money',
  customerDetail: '/customers/:customerId',
  requestPayments: '/transactions',
  requestPaymentDetail: '/transactions/:externalId',
  pos: '/pos',
  posDetail: '/pos/:externalId',
  paymentLink: '/payment-link',
};

export default RoutePaths;
