import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getBalanceReportMenu = (userScopes: string[]) => {
  const BalanceReports: SubMenuItemType[] = [
    {
      path: RoutePaths.balanceReport,
      icon: 'money-check-alt',
      title: 'balance_report',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.merchantAvailableBalance),
    },
  ];

  return BalanceReports;
};

const getBalanceReportRoutes = (userScopes: string[]) => {
  const BalanceReportRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.balanceReport,
      component: React.lazy(() => import('../../pages/balance-report/balance-report')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.merchantAvailableBalance),
    },
  ];

  return BalanceReportRoutes;
};

export default getBalanceReportRoutes;
