import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { role } from '../../config/constants/roles';
import { SubMenuItemType } from '../../config/constants/menu.types';

export const getSettlementMenu = (userScopes: string[]) => {
  const settlementsMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.createSettlement,
      icon: 'plus',
      title: 'create_settlement',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.settlements),
    },
    {
      path: RoutePaths.settlements,
      icon: 'list',
      title: 'settlements_list',
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.settlements),
    },
  ];

  return settlementsMenu;
};

const getSettlementRoutes = (userScopes: string[]) => {
  const SettlementRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.settlements,
      component: React.lazy(() => import('../../pages/settlement/settlement-list')),
      roles: [role.internal, role.merchant],
      hasPermission: hasPermission(userScopes, scopes.settlements),
    },
    {
      path: RoutePaths.createSettlement,
      component: React.lazy(
        () => import('../../pages/settlement/create-settlement/create-settlement-page'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.settlements),
    },
    {
      path: RoutePaths.settlementDetail,
      component: React.lazy(
        () => import('../../pages/settlement/settlement-detail/settlement-detail'),
      ),
      roles: [role.merchant, role.internal],
      hasPermission: hasPermission(userScopes, scopes.settlements),
    },
  ];

  return SettlementRoutes;
};

export default getSettlementRoutes;
