import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getCashoutMenu = (userScopes: string[]) => {
  const CashoutMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.cashoutList,
      icon: 'arrow-right',
      title: 'nav_cashout',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.transactionsReports),
    },
  ];
  return CashoutMenu;
};

const getCashoutRoutes = (userScopes: string[]) => {
  const CashoutRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.cashoutList,
      component: React.lazy(() => import('../../pages/cashout/cashout-list')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.transactionsReports),
    },
    {
      path: RoutePaths.cashoutDetail,
      component: React.lazy(() => import('../../pages/cashout/detail/cashout-details')),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.transactionsDetails),
    },
  ];

  return CashoutRoutes;
};

export default getCashoutRoutes;
