import * as Amplitude from '@amplitude/analytics-browser';

type BaseEventProps = Amplitude.Types.BaseEvent;

const keyAmplitude = process.env.REACT_APP_AMPLITUDE_KEY || '';

export default class AmplitudeTracker {
  static init = (init: boolean) => {
    if (init) {
      Amplitude.init(keyAmplitude, {
        defaultTracking: {
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
          pageViews: true,
        },
      });
    }
  };

  static trackEvent = (eventName: string, props: BaseEventProps) => {
    Amplitude.track(eventName, props);
  };

  static setUserId = (userId: string) => {
    Amplitude.setUserId(userId);
  };
}
