import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { CashoutFilterState } from './types';
import { dateByDefault, dateWeekByDefault } from '../../../config/constants/date-formats';

const initialState: CashoutFilterState = {
  cashoutFilters: dateByDefault,
  cashoutDates: dateByDefault,
  cashoutId: '',
  cashoutReference: '',
  cashoutCountry: '',
  cashoutStatus: '',
  cashoutMerchant: '',
  cashoutOnlyActives: false,
  cashoutMerchantUserId: '',
};

export const cashoutFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    cashoutFilters: (state, action: PayloadAction<string>) => {
      state.cashoutFilters = action.payload;
    },
    cashoutDates: (state, action: PayloadAction<string>) => {
      state.cashoutDates = action.payload;
    },
    cashoutId: (state, action: PayloadAction<string>) => {
      state.cashoutId = action.payload;
    },
    cashoutReference: (state, action: PayloadAction<string>) => {
      state.cashoutReference = action.payload;
    },
    cashoutCountry: (state, action: PayloadAction<string>) => {
      state.cashoutCountry = action.payload;
    },
    cashoutStatus: (state, action: PayloadAction<string>) => {
      state.cashoutStatus = action.payload;
    },
    cashoutMerchant: (state, action: PayloadAction<string>) => {
      state.cashoutMerchant = action.payload;
    },
    cashoutOnlyActives: (state, action: PayloadAction<boolean>) => {
      state.cashoutOnlyActives = action.payload;
    },
    cashoutMerchantUserId: (state, action: PayloadAction<string>) => {
      state.cashoutMerchantUserId = action.payload;
    },
    resetCashoutFilters: (state) => {
      state.cashoutFilters = initialState.cashoutFilters;
      state.cashoutDates = initialState.cashoutDates;
      state.cashoutId = initialState.cashoutId;
      state.cashoutReference = initialState.cashoutReference;
      state.cashoutCountry = initialState.cashoutCountry;
      state.cashoutStatus = initialState.cashoutStatus;
      state.cashoutMerchant = initialState.cashoutMerchant;
      state.cashoutOnlyActives = initialState.cashoutOnlyActives;
      state.cashoutMerchantUserId = initialState.cashoutMerchantUserId;
    },
    initCashoutMerchantFilters: (state, action: PayloadAction<string>) => {
      state.cashoutMerchant = `&merchant=${action.payload}`;
      state.cashoutFilters = `${dateWeekByDefault}&merchant=${action.payload}`;
      state.cashoutDates = dateWeekByDefault;
    },
  },
});

// Selectors
const getCashoutFilters = (state: RootState): CashoutFilterState => state.cashoutFilters;
export const getCashoutsFilters = createSelector(
  getCashoutFilters,
  (filter) => filter.cashoutFilters,
);
export const getCashoutDates = createSelector(getCashoutFilters, (filter) => filter.cashoutDates);
export const getCashoutId = createSelector(getCashoutFilters, (filter) => filter.cashoutId);
export const getCashoutReference = createSelector(
  getCashoutFilters,
  (filter) => filter.cashoutReference,
);
export const getCashoutCountry = createSelector(
  getCashoutFilters,
  (filter) => filter.cashoutCountry,
);
export const getCashoutStatus = createSelector(getCashoutFilters, (filter) => filter.cashoutStatus);
export const getCashoutMerchant = createSelector(
  getCashoutFilters,
  (filter) => filter.cashoutMerchant,
);
export const getCashoutOnlyActives = createSelector(
  getCashoutFilters,
  (filter) => filter.cashoutOnlyActives,
);
export const getCashoutMerchantUserId = createSelector(
  getCashoutFilters,
  (filter) => filter.cashoutMerchantUserId,
);

const { actions, reducer } = cashoutFilterSlice;

export const {
  cashoutFilters,
  cashoutDates,
  cashoutId,
  cashoutReference,
  cashoutCountry,
  cashoutStatus,
  cashoutMerchant,
  cashoutOnlyActives,
  cashoutMerchantUserId,
  resetCashoutFilters,
  initCashoutMerchantFilters,
} = actions;

export default reducer;
