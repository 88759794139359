import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { SubMenuItemType } from '../../config/constants/menu.types';
import { role } from '../../config/constants/roles';

export const getDepositFGMenu = (userScopes: string[]) => {
  const DepositMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.fullGatewayDeposits,
      icon: 'arrow-left',
      title: 'fullgatewaydeposits',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.fullgatewayReports),
    },
  ];
  return DepositMenu;
};

const getFullgatewayDepositRoutes = (userScopes: string[]) => {
  const FullgatewayDepositRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.fullGatewayDeposits,
      component: React.lazy(
        () => import('../../pages/fullgateway-deposits/fullgateway-deposit-page'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.fullgatewayReports),
    },
    {
      path: RoutePaths.fullGatewayDepositsDetails,
      component: React.lazy(
        () => import('../../pages/fullgateway-deposits/fullgateway-deposit-detail'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.fullgatewayReports),
    },
  ];
  return FullgatewayDepositRoutes;
};

export default getFullgatewayDepositRoutes;
