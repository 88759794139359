import React from 'react';

import RoutePaths from '../../config/constants/route-paths';
import scopes, { hasPermission } from '../../config/constants/scopes';
import { RouteTypeView } from '../../config/constants/routes.types';
import { role } from '../../config/constants/roles';
import { SubMenuItemType } from '../../config/constants/menu.types';

export const getCashoutFGMenu = (userScopes: string[]) => {
  const CashoutMenu: SubMenuItemType[] = [
    {
      path: RoutePaths.fullGatewayCashouts,
      icon: 'arrow-left',
      title: 'fullgatewaycashouts',
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.fullgatewayReports),
    },
  ];
  return CashoutMenu;
};

const getFullgatewayCashoutsRoutes = (userScopes: string[]) => {
  const FullgatewayCashoutsRoutes: RouteTypeView[] = [
    {
      path: RoutePaths.fullGatewayCashouts,
      component: React.lazy(
        () => import('../../pages/fullgateway-cashouts/fullgateway-cashouts-page'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.fullgatewayReports),
    },
    {
      path: RoutePaths.fullGatewayCashoutsDetails,
      component: React.lazy(
        () => import('../../pages/fullgateway-cashouts/fullgateway-cashouts-detail'),
      ),
      roles: [role.merchant],
      hasPermission: hasPermission(userScopes, scopes.fullgatewayReports),
    },
  ];

  return FullgatewayCashoutsRoutes;
};

export default getFullgatewayCashoutsRoutes;
